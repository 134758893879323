<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { avaliableCategoryList } from '@kidzonet/consts'
import { useGetCategoryName } from '@kidzonet/use-categories'
import { difference } from '@kidzonet/utils-sets'

interface CategorySelectorPropsInterface {
    unusedCategoriesList?: number[]
}

const props = withDefaults(defineProps<CategorySelectorPropsInterface>(), {
    unusedCategoriesList: () => [],
})

const { t } = useI18n()

/**
 * get sorted list of translated categories
 */
const sortedCategorieslList = computed(() => [...difference(
    avaliableCategoryList, props.unusedCategoriesList,
)].map(id => (
    { value: id, title: useGetCategoryName(id) }
)).sort((a, b) => {
    if (a.title > b.title) {
        return 1
    }
    if (a.title < b.title) {
        return -1
    }
    return 0
}))
</script>

<template>
    <v-autocomplete
        :items="sortedCategorieslList"
        :label="t('common.category')"
        clearable
        autocomplete="off"
        variant="outlined"
        color="primary"
        bg-color="mainContentBackground"
        class="category-selector"
    />
</template>

<style>
    .category-selector {
        .v-autocomplete__selection-text {
            overflow: hidden;
            white-space: nowrap;
        }
    }
</style>
