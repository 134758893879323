import { defineStore } from '@kidzonet/pinia'
import { deleteSession, getCurrentUserSessions, getUserSessions } from '@kidzonet/ts-api-dashboard-sessions'

import { sessionItemInterface } from './types'

type idType = string | number | null

export const useSessionsStore = defineStore('account-sessions', {
    state: () => {
        const sessions: sessionItemInterface[] = []
        return {
            sessions,
            isSessionLoading: false,
        }
    },
    actions: {
        async getSessions (id: idType = null) {
            this.isSessionLoading = true
            let result
            if (id) {
                result = await getUserSessions(String(id)) || []
            } else {
                result = await getCurrentUserSessions() || []
            }
            this.$patch({
                sessions: result,
                isSessionLoading: false,
            })
        },
        async deleteSession (id: idType) {
            if (!id) {
                return true
            }
            deleteSession(String(id))
            this.sessions = this.sessions.filter(({ sessionId }) => sessionId !== id)
            return true
        },
    },
})
