<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Image from './Image.vue'

const { t } = useI18n()
</script>

<template>
    <div class="kidzonet-dashboard-no-data">
        <Image />
        <span class="kidzonet-dashboard-no-data__label">
            {{ t('web_common.data.no_data_available') }}
        </span>
    </div>
</template>

<style>
    .kidzonet-dashboard-no-data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        &__label {
            color: #888C9A;
        }
    }
</style>
