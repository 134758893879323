<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import PolicyPage from '@kidzonet/dashboard-custom-policy-page'
import { DEFAULT_PER_PAGE, useCustomPolicyStore } from '@kidzonet/dashboard-custom-policy-store'
import { useClickOnCurrentMenuItemListener } from '@kidzonet/vue3-event-bus'

const { t } = useI18n()
const store = useCustomPolicyStore()
store.perPage = DEFAULT_PER_PAGE
store.fetchAllowedDomainList()
useClickOnCurrentMenuItemListener(store.fetchAllowedDomainList)
</script>

<template>
    <PolicyPage
        :loading="store.allowedDomainsLoading"
        :page="store.allowedDomainsPage"
        :total="store.allowedDomainsTotal"
        :list="store.allowedDomains"
        :header="t('dashboard_v2.custom_policy_page.table.main_header.allowed_domains')"
        :add-label="t('dashboard_v2.custom_policy_page.add_form.allowed_domain_label')"
        :pages-count="store.allowedPagesCount"
        :sort-order="store.allowedListSortOrder"
        @add-domain="store.addAllowedDomain"
        @remove-domain="store.removeAllowedDomain"
        @paginate="store.paginateAllowDomains"
        @change-sort-order="store.changeSortAllowedDomains"
    />
</template>
