import {
    CreateScheduleListResponseInterface,
    CreateScheduleResponseInterface,
    GetScheduleListResponseInterface,
    ScheduleCreateSchema,
    ScheduleSchema,
} from './types'

declare global {
    // eslint-disable-next-line vars-on-top, no-var
    var DashboardApiClient: any
}

const apiAnswerToInterval = (item: ScheduleSchema) => ({
    id: item.id,
    policies: item.policies,
    weekdays: item.weekdays,
    startTime: item.start_time,
    stopTime: item.stop_time,
})

export const getSchedulesList = async (id?: number | null) => {
    const { data, error }: GetScheduleListResponseInterface = await globalThis.DashboardApiClient.GET(
        '/v1/public/dashboard/schedules',
        {
            params: {
                query: { policy_id: id },
            },
        },
    )

    if (error) {
        throw error
    }
    const { items = [] } = data
    return items.map(item => ({
        id: item.id,
        policies: item.policies,
        weekdays: item.weekdays,
        startTime: item.start_time,
        stopTime: item.stop_time,
    }))
}

export const createSchedule = async (body: ScheduleCreateSchema) => {
    const { data, error, response }: CreateScheduleListResponseInterface = await globalThis.DashboardApiClient.POST(
        '/v1/public/dashboard/schedules',
        {
            body: {
                ...body,
                start_time: body.start_time.padStart(5, '0'),
                stop_time: body.stop_time.padStart(5, '0'),
            },
        },
    )

    if (error) {
        if ('status' in response && response.status && [409, 422].includes(response.status)) {
            return {
                ...error,
                status: response.status,
            }
        }
        throw error
    }
    return apiAnswerToInterval(data)
}

export const getSchedule = async (id: number) => {
    const { data, error }: CreateScheduleResponseInterface = await globalThis.DashboardApiClient.GET(
        `/v1/public/dashboard/schedules/${id}`,
    )

    if (error) {
        throw error
    }
    return apiAnswerToInterval(data)
}

export const deleteSchedule = async (id: number) => {
    await globalThis.DashboardApiClient.DELETE(
        `/v1/public/dashboard/schedules/${id}`,
    )
    return true
}
