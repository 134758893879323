<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
    <v-btn-toggle
        color="primary"
        mandatory
        multiple
    >
          <v-btn value="mon">
              {{ t('common.days_of_week.short.mon') }}
          </v-btn>
          <v-btn value="tue">
              {{ t('common.days_of_week.short.tue') }}
          </v-btn>
          <v-btn value="wed">
              {{ t('common.days_of_week.short.wed') }}
          </v-btn>
          <v-btn value="thu">
              {{ t('common.days_of_week.short.thu') }}
          </v-btn>
          <v-btn value="fri">
              {{ t('common.days_of_week.short.fri') }}
          </v-btn>
          <v-btn value="sat">
              {{ t('common.days_of_week.short.sat') }}
          </v-btn>
          <v-btn value="sun">
              {{ t('common.days_of_week.short.sun') }}
          </v-btn>
    </v-btn-toggle>
</template>
