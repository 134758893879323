<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useSettingsStore } from '@kidzonet/dashboard-settings-store'
import SafeYouTubeIcon from '@kidzonet/dashboard-policy-icons/src/SafeYouTube.vue'

import PolicyItem from '@kidzonet/dashboard-policy-widget'
import Switcher from './Switcher.vue'

const { t } = useI18n()
const store = useSettingsStore()
const change = () => {
    store.setSafeYoutube(!store.safeYoutube)
}
</script>

<template>
    <PolicyItem
        :is-active="store.safeYoutube"
        :header="t('common.manual_policy.safe_youtube')"
        :subheader="t('common.manual_policy.safe_youtube_description')"
        @click="change"
    >
        <template #icon>
            <SafeYouTubeIcon />
        </template>
        <slot>
            <Switcher
                :model-value="store.safeYoutube"
            />
        </slot>
    </PolicyItem>
</template>
