<script lang="ts" setup>
import { useSupportStore } from '@kidzonet/dashboard-support-store'
import Toolbar from './Toolbar/index.vue'

const store = useSupportStore()
store.fetchData()
</script>

<template>
    <div>
        <Toolbar />
        <div class="support-page__content">
            <router-view />
        </div>
    </div>
</template>

<style>
    .support-page {
        &__content {
            padding: 1em 2em;
        }
    }
</style>
