<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTheme } from 'vuetify'
import { DARK_MODE_NAME } from '@kidzonet/consts'

const theme = useTheme()

const dark = ref(theme.global.current.value.dark)

const changeTheme = () => {
    localStorage.setItem(DARK_MODE_NAME, String(dark.value))
    theme.global.name.value = dark.value ? 'dark' : 'light'
    document.body.classList.remove('dark')
    document.body.classList.remove('light')
    document.body.classList.add(theme.global.name.value)
}

const { t } = useI18n()
</script>

<template>
    <v-switch
        :label="t('common.settings.dark_mode')"
        v-model="dark"
        @change="changeTheme"
    />
</template>
