<script setup lang="ts">
</script>

<template>
    <div class="kidzonet-policy-icon">
        <slot />
    </div>
</template>

<style>
    .kidzonet-policy-icon {
        min-width: 48px;
        min-height: 48px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media(max-width: 600px) {
            display: none;
        }
    }
</style>
