import { defineStore } from '@kidzonet/pinia'

import {
    getSettings,
    setSafeSearch as patchSafeSearch,
    setSafeYoutube as patchSafeYoutube,
    setSettings as patchSettings,
    setSimpleUI as patchSimpleUI,
} from '@kidzonet/ts-api-dashboard-settings'

export const useSettingsStore = defineStore('settings', {
    state: () => {
        return {
            loading: false,
            safeSearch: false,
            safeYoutube: false,
            simpleUI: false,
        }
    },
    actions: {
        async setSettings ({ safeSearch, safeYoutube }: { safeSearch: boolean, safeYoutube: boolean }) {
            if (this.loading) {
                return
            }
            this.loading = true
            const result = await patchSettings({ safeSearch, safeYoutube })
            if (result) {
                this.safeSearch = safeSearch
                this.safeYoutube = safeYoutube
            }
            this.loading = false
        },
        async setSafeSearch (value: boolean) {
            if (this.loading) {
                return
            }
            this.loading = true
            const result = await patchSafeSearch(value)
            if (result) {
                this.safeSearch = value
            }
            this.loading = false
        },
        async setSafeYoutube (value: boolean) {
            if (this.loading) {
                return
            }
            this.loading = true
            const result = await patchSafeYoutube(value)
            if (result) {
                this.safeYoutube = value
            }
            this.loading = false
        },
        async setSimpleUI (value: boolean) {
            if (this.loading) {
                return
            }
            this.loading = true
            const result = await patchSimpleUI(value)
            if (result) {
                this.simpleUI = value
            }
            this.loading = false
        },
        async fetchSettings () {
            this.loading = true
            const result = await getSettings()
            if (result) {
                const { safeSearch, safeYoutube, simpleUI } = result
                this.safeSearch = safeSearch
                this.safeYoutube = safeYoutube
                this.simpleUI = simpleUI
            }
            this.loading = false
        },
    },
})
