<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Content from './PolicyDescription.vue'

const { t } = useI18n()

const dialog = ref(false)
</script>

<template>
    <v-dialog
        v-model="dialog"
        width="auto"
        transition="dialog-bottom-transition"
    >
        <template #activator="{ props }">
            <div
                class="policy-header-button"
                v-bind="props"
            >
                {{ t('dashboard_v2.policy_page.header_button') }}
            </div>
        </template>
        <Content
            @close="() => dialog = false"
        />
    </v-dialog>
</template>

<style>
    .policy-header-button {
        border-radius: 8px;
        background-color: rgb(var(--v-theme-primary));
        padding: 8px 12px;
        font-size: 14px;
        color: rgb(var(--v-theme-mainMenuText));;
        cursor: pointer;
        user-select: none;
    }
</style>
