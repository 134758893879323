import { inject, onMounted, onUnmounted } from 'vue'
import { $busOff, $busOn } from '@kidzonet/inject-bus-emit'

export const useClickOnCurrentMenuItemListener = (refreshData: () => void) => {
    const busOn = inject($busOn)
    const busOff = inject($busOff)

    onMounted(() => {
        if (busOn) {
            busOn('click_on_current_menu_item', refreshData)
        }
    })

    onUnmounted(() => {
        if (busOff) {
            busOff('click_on_current_menu_item', refreshData)
        }
    })
}
