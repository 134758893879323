<script setup lang="ts">
import { useLoadLocaleMessages } from '@kidzonet/vue-i18n-package'
import { useStatsStore } from '@kidzonet/dashboard-stats-store'
import { useClickOnCurrentMenuItemListener } from '@kidzonet/vue3-event-bus'
import SimpleInterface from '@kidzonet/dashboard-simple-interface'
import { useSettingsStore } from '@kidzonet/dashboard-settings-store'

import CurrentPolicy from './CurrentPolicy/index.vue'
import StatisticTable from './StatisticTable/index.vue'
import CustomPolicy from './CustomPolicy/index.vue'
import MostPopularCategories from './MostPopularCategories/index.vue'
import TodaysRequests from './TodaysRequests/index.vue'

useLoadLocaleMessages('categories')
useLoadLocaleMessages('user_statistic')

const store = useStatsStore()
const settingsStore = useSettingsStore()

store.fetchFirstFiveItems()

useClickOnCurrentMenuItemListener(store.fetchFirstFiveItems)
</script>

<template>
    <SimpleInterface
        v-if="settingsStore.simpleUI"
    />
    <div
        v-else
        class="dashboard-overview-page"
    >
        <CurrentPolicy
            class="dashboard-overview-page__current-policy"
        />
        <CustomPolicy
            class="dashboard-overview-page__custom-policy"
        />
        <TodaysRequests
            class="kidzonet-dashboard-widget dashboard-overview-page__todays-requests"
        />
        <MostPopularCategories
            class="kidzonet-dashboard-widget dashboard-overview-page__most-popular-categories"
        />
        <StatisticTable
            class="kidzonet-dashboard-widget dashboard-overview-page__statistic-table"
        />
    </div>
</template>

<style>
    .dashboard-overview-page {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 1em;
        grid-template-areas:
            "policy policy policy custom-policy custom-policy custom-policy"
            "today-requests pop-cats pop-cats pop-cats pop-cats pop-cats"
            "statistic-table statistic-table statistic-table statistic-table statistic-table statistic-table";

        @media(max-width: 900px) {
            display: flex;
            flex-direction: column;
        }
    }

    .dashboard-overview-page__current-policy {
        grid-area: policy;
    }
    .dashboard-overview-page__custom-policy {
        grid-area: custom-policy;
    }
    .dashboard-overview-page__todays-requests {
        grid-area: today-requests;
    }
    .dashboard-overview-page__most-popular-categories {
        grid-area: pop-cats;
    }
    .dashboard-overview-page__statistic-table {
        grid-area: statistic-table;
    }
    .overview-header-2 {
        font-size: 14px;
        font-weight: 400;
    }
</style>
