<script setup lang="ts">
import Paginator from '@kidzonet/paginator'

import AddForm from './AddForm.vue'
import ExclusionTable from './Table.vue'
import { ExclusionType } from '@kidzonet/ts-api-exclusion-lists/src/types'

interface DomainInterface {
    domain: string
    id: number
    type: ExclusionType
}

interface CustomPolicyPageInterface {
    loading: boolean
    page: number
    total: number
    header: string
    addLabel: string
    pagesCount: number
    sortOrder: 'asc' | 'desc'
    list: DomainInterface[]
}

const props = defineProps<CustomPolicyPageInterface>()

const emit = defineEmits<{
    (e: 'addDomain', value: string): void
    (e: 'removeDomain', domain: DomainInterface): void
    (e: 'paginate', value: number): void
    (e: 'changeSortOrder'): void
}>()
</script>

<template>
    <div class="custom-policy-page">
        <AddForm
            class="kidzonet-dashboard-widget"
            :label="props.addLabel"
            :list="list"
            @add-domain="(domain: string) => emit('addDomain', domain)"
        />
        <ExclusionTable
            v-bind="props"
            @remove-domain="(domain: DomainInterface) => emit('removeDomain', domain)"
            @change-sort-order="() => emit('changeSortOrder')"
        />
        <Paginator
            v-bind="props"
            @paginate="(page: number) => emit('paginate', page)"
            class="custom-policy-page__paginator"
        />
    </div>
</template>

<style>
    .custom-policy-page {
        &__paginator {
            margin-block-start: 1em;
        }
    }
</style>
