<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t, locale } = useI18n({ useScope: 'global' })

const appStoreImg = computed(() => {
    if (locale.value === 'ar') {
        return new URL('../../../assets/src/markets/ios/ar.svg', import.meta.url)
    }
    if (locale.value === 'fr') {
        return new URL('../../../assets/src/markets/ios/fr.svg', import.meta.url)
    }
    return new URL('../../../assets/src/markets/ios/en.svg', import.meta.url)
})
const playMarketImg = computed(() => {
    if (locale.value === 'ar') {
        return new URL('../../../assets/src/markets/google/ar.png', import.meta.url)
    }
    if (locale.value === 'fr') {
        return new URL('../../../assets/src/markets/google/fr.png', import.meta.url)
    }
    return new URL('../../../assets/src/markets/google/en.png', import.meta.url)
})
</script>

<template>
    <div class="mobile-app-links">
        <div class="mobile-app-links__header">
            <div class="mobile-app-list-item">
                1
            </div>
            <div>
                {{ t('dashboard_v2.mobile.install_app_link') }}
            </div>
        </div>
        <div class="mobile-app-links__images">
            <a
                target="_blank"
                href="https://apps.apple.com/us/app/id1462294237"
            >
                <img
                    class="mobile-app-links__img"
                    :src="appStoreImg.pathname"
                >
            </a>
            <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.kidzonet"
            >
                <img
                    class="mobile-app-links__img"
                    :src="playMarketImg.pathname"
                >
            </a>
        </div>
    </div>
</template>

<style>
    .mobile-app-links {
        &__header {
            display: flex;
            gap: 1em;
            flex-wrap: wrap;
            margin: 1em 0;
            align-items: center;
        }

        &__images {
            display: flex;
            gap: 1em;
            flex-wrap: wrap;
            margin: 1em 0;
        }

        &__img {
            width: 226px;
            height: 73px;
        }
    }
</style>
