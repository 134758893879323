<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const showPassword = ref(false)
const passwordRef = ref()

const togglePassword = () => {
    showPassword.value = !showPassword.value
}

const passwordType = computed(() => {
    return showPassword.value ? 'text' : 'password'
})

const passwordAppendIcon = computed(() => {
    return showPassword.value ? 'passwordFieldEyeSlash' : 'passwordFieldEye'
})

const focus = () => {
    passwordRef.value.focus()
}

defineExpose({ focus })
</script>

<template>
    <v-text-field
        id="password_input"
        ref="passwordRef"
        :label="t('login.password')"
        autocomplete="new-password"
        variant="outlined"
        :type="passwordType"
        :append-inner-icon="passwordAppendIcon"
        @click:append-inner="togglePassword"
        color="primary"
    />
</template>
