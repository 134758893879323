import { SessionSchema } from './types'

const sanitizeDateString = (date: string) => {
    if (date?.endsWith?.('Z')) {
        return date.substring(0, date.length - 1)
    }
    return date
}

export const getSessionFromResponse = (session: SessionSchema) => ({
    expiresAt: sanitizeDateString(session.expires_at),
    createdAt: sanitizeDateString(session.created_at),
    sessionId: session.session_id,
    role: session.role,
    userAgent: session?.extra?.user_agent,
    loginIP: session?.extra?.login_ip,
    lastIP: session?.extra?.last_ip,
    lastActivity: session?.extra?.last_activity,

})
