const LoginLayout = () => import('@kidzonet/dashboard-login-layout')
const Login = () => import('@kidzonet/login')
const extLogin = () => import('@kidzonet/login-ext-login')
const SSO = () => import('@kidzonet/sso-login')
const Register = () => import('@kidzonet/dashboard-register')
const ResotrePasswordRoot = () => import('@kidzonet/dashboard-login-restore-password')
const ResotrePasswordForm = () => import('@kidzonet/dashboard-login-restore-password/src/RestorePasswordForm.vue')
const ResotrePasswordNewPasswordForm = () => import('@kidzonet/dashboard-login-restore-password/src/NewPasswordForm.vue')

export default [{
    path: '/',
    component: LoginLayout,
    children: [{
        path: '',
        name: 'login-root',
        component: Login,
    }, {
        path: '/sso',
        name: 'sso',
        component: SSO,
    }, {
        path: '/signup',
        name: 'register',
        component: Register,
    }, {
        path: '/restore-password',
        component: ResotrePasswordRoot,
        children: [{
            path: '',
            name: 'restore-password',
            component: ResotrePasswordForm,
        }, {
            path: '/new-password',
            component: ResotrePasswordNewPasswordForm,
        }],
    }],
}, {
    path: '/ext-login/:t',
    component: extLogin,
    props: true,
    name: 'ext-login',
}]
