<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { LANG_NAMES } from '@kidzonet/consts'
import { AVALIABLE_LANGUAGES } from '@kidzonet/vue-i18n-package'

import LanguageSelectorItem from './Item.vue'

const { t } = useI18n()

const avaliableLanguages = computed(() => AVALIABLE_LANGUAGES.map(value => ({
    title: t(`common.language_selector.${value}`),
    value,
    original: LANG_NAMES[value],
})).sort((a, b) => {
    if (a.title < b.title) {
        return -1
    }
    return 1
}))
</script>

<template>
    <v-select
        class="language-selector"
        v-bind="$attrs"
        :items="avaliableLanguages"
        variant="underlined"
    >
        <template #item="data">
            <v-list-item
                v-bind="data.props"
                :key="data.item.value"
                :value="data.item.value"
                :title="data.item.title"
                :subtitle="data.item.raw.original"
            />
        </template>
        <template #selection="{ item }">
            <LanguageSelectorItem
                :title="item.title"
            />
        </template>
    </v-select>
</template>

<style>
    .language-selector {
        align-self: stretch;
    }
</style>
