<script setup lang="ts">
import { computed } from 'vue'
import { useTheme } from 'vuetify'
import { KidsHomeAloneColors } from '@kidzonet/consts/src/PolicyColors'
import { useEnvVar } from '@kidzonet/vue3-env-var'
import RamadanTimeIcon from './RamadanTime.vue'
import IconWrapper from './BaseIcon.vue'

const showRamadanTimePolicy = computed(() => useEnvVar('VITE_SHOW_RAMADAN_TIME_POLICY'))

const theme = useTheme()

const iconColor = computed(
    () => theme.global.current.value.dark
        ? KidsHomeAloneColors.icon.dark
        : KidsHomeAloneColors.icon.light,
)
const backgroundColor = computed(
    () => theme.global.current.value.dark
        ? KidsHomeAloneColors.background.dark
        : KidsHomeAloneColors.background.light,
)
</script>

<template>
    <RamadanTimeIcon
        v-if="showRamadanTimePolicy"
    />
    <IconWrapper
        v-else
        :style="{ backgroundColor }"
    >
        <v-icon
            icon="PolicyIconKidsHomeAlone"
            :color="iconColor"
            size="24"
        />
    </IconWrapper>
</template>
