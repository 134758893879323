<script lang="ts" setup>
import { useScheduleStore } from '@kidzonet/dashboard-schedule-store'

import IntervalChip from './IntervalChip.vue'

type shortDayName = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun'

interface IntervalItemProps {
    id: number
    policyId: number
    weekdays: shortDayName[]
    startTime: string
    stopTime: string
    name: string
}

const props = defineProps<IntervalItemProps>()

const store = useScheduleStore()

const removeInterval = (id: number) => {
    store.removeInterval({
        name: props.name,
        id,
        policyId: props.policyId,
    })
}
</script>

<template>
    <li>
        <IntervalChip
            closable
            v-bind="props"
            @click:close="removeInterval(id)"
        />
    </li>
</template>
