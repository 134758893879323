<script setup lang="ts">
import { computed } from 'vue'
import { useTheme } from 'vuetify'
import { useI18n } from 'vue-i18n'
import PolicyWidget from '@kidzonet/dashboard-overview-policy-widget'
import { getPolicyName, getPolicyDescription } from '@kidzonet/use-policy'

interface PolicyItemProps {
    name?: string
    isActive?: boolean
}

const props = withDefaults(defineProps<PolicyItemProps>(), {
    name: '',
})

const theme = useTheme()

const { t } = useI18n()

const classList = computed(() => {
    const result = ['policy-item']
    if (props.isActive) {
        result.push('policy-item--active')
    }
    if (theme.global.current.value.dark) {
        result.push('policy-item--dark')
    }
    return result
})
</script>

<template>
    <PolicyWidget
        :header="getPolicyName(name)"
        :subheader="getPolicyDescription(name)"
        :class="classList"
        v-ripple="!props.isActive"
    >
        <template #icon>
            <slot name="icon" />
        </template>
        <template #action>
            <slot />
        </template>
        <slot name="bottom" />
    </PolicyWidget>
</template>

<style>
    .policy-item {
        --active-border-color: #C0BCE5;
        --active-background-color: #FCFBFE;

        &--dark {
            --active-border-color: inherit;
            --active-background-color: #000;
        }

        border: 1px solid #E4E4E4;
        cursor: pointer;

        &:hover, &--active {
            border-color: var(--active-border-color);
            background-color: var(--active-background-color) !important;
        }

        @media(max-width: 1300px) {
            height: 7em;
        }

        @media(max-width: 1100px) {
            height: auto;
        }
    }

    .policy-item__icon-wrapper {
        min-width: 48px;
        min-height: 48px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
