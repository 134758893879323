import { defineStore } from '@kidzonet/pinia'

import { booleanToOrderString } from '@kidzonet/store-helpers/sort'
import { DNSLogSort, getDNSLog } from '@kidzonet/ts-api-dashboard-dns-log'

import { statusType } from '@kidzonet/ts-api-dashboard-dns-log/src/types'

const defaultDescending = true

const PER_PAGE_DEFAULT = 10

interface dnsLogItem {
    domain: string
    domainCats: number[]
    timestamp: number
    type: 0 | 1 | 2 | 3 | 4
}

export type sortByType = 'timestamp' | 'domain' | 'domainCats' | 'type'

interface StoreInterface {
    dnsLog: dnsLogItem[]
    loading: boolean
    total: number
    sortBy: sortByType
    descending: boolean
    page: number
    categoryFilter: number | null
    statusFilter: statusType
    domain: string
    perPage: number
}

export const useStatsStore = defineStore('stats', {
    state: () => {
        const state: StoreInterface = {
            dnsLog: [],
            loading: false,
            total: 0,
            sortBy: 'timestamp',
            descending: defaultDescending,
            page: 1,
            categoryFilter: null,
            statusFilter: null,
            domain: '',
            perPage: PER_PAGE_DEFAULT,
        }
        return state
    },
    persist: {
        key: 'kidzonet-dashboard-reports',
        paths: ['sortBy', 'descending', 'categoryFilter', 'statusFilter', 'domain'],
    },
    getters: {
        pagesCount: ({ total }) => Math.ceil(total / PER_PAGE_DEFAULT),
        sortName: ({ descending }) => booleanToOrderString(descending),
        status: ({ statusFilter }) => statusFilter || null,
    },
    actions: {
        async fetchFirstFiveItems () {
            this.perPage = 5
            await this.fetchDNSLog()
        },
        async fetchStatistic () {
            this.perPage = PER_PAGE_DEFAULT
            await this.fetchDNSLog()
        },
        async changeStatus (status: statusType) {
            this.statusFilter = status
            await this.fetchDNSLog()
        },
        async changeCategory (category: number | null) {
            this.categoryFilter = category
            await this.fetchDNSLog()
        },
        async searchByDomain (search: string) {
            this.domain = search
            await this.fetchDNSLog()
        },
        async fetchDNSLog () {
            this.loading = true
            const filters = {
                page: this.page,
                per_page: this.perPage,
                sort: DNSLogSort[this.sortBy],
                desc: this.descending,
                status: this.status,
                category: this.categoryFilter,
                domain: this.domain,
            }
            const log = await getDNSLog(filters)
            if (log) {
                this.$patch({
                    dnsLog: log.items,
                    total: log.total,
                })
            }
            this.loading = false
        },
        async paginate (page: number) {
            this.page = page
            await this.fetchDNSLog()
        },
        async changeSort (sortBy: sortByType) {
            this.page = 1
            this.descending = !this.descending
            this.sortBy = sortBy
            await this.fetchDNSLog()
        },
    },
})
