<script setup lang="ts">
import { useDasboardPolicyStore } from '@kidzonet/dashboard-policy-store'

import BlockYoutubeIcon from '@kidzonet/dashboard-policy-icons/src/BlockYoutube.vue'
import BlockSocialMediaIcon from '@kidzonet/dashboard-policy-icons/src/BlockSocialMedia.vue'
import BlockOnlineGamesIcon from '@kidzonet/dashboard-policy-icons/src/BlockOnlineGames.vue'
import BlockOnlineMusicAndVideoIcon from '@kidzonet/dashboard-policy-icons/src/BlockOnlineMusicAndVideo.vue'

import PolicyItem from '@kidzonet/dashboard-policy-widget'
import SafeSearch from './SafeSearch.vue'
import SafeYoutube from './SafeYoutube.vue'
import Switcher from './Switcher.vue'

const store = useDasboardPolicyStore()
</script>

<template>
    <div
        v-if="store.policySwitchers && store.policySwitchers.length > 0"
        class="protection-settings"
    >
        <PolicyItem
            v-for="{ name, isActive = false, id } in store.policySwitchers"
            :key="name"
            :name="name"
            :id="id"
            :is-active="isActive"
            @click="() => store.activatePolicy(id)"
        >
            <template #icon>
                <BlockYoutubeIcon v-if="name === 'youtube'" />
                <BlockSocialMediaIcon v-else-if="name === 'social_media'" />
                <BlockOnlineGamesIcon v-else-if="name === 'online_games'" />
                <BlockOnlineMusicAndVideoIcon v-else-if="name === 'online_music_and_video'" />
            </template>
            <Switcher
                :model-value="isActive"
            />
        </PolicyItem>
        <SafeSearch />
        <SafeYoutube />
    </div>
    <div
        v-else
        class="protection-settings-fake-content"
    />
</template>

<style>
    .protection-settings {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
        margin-block-start: 1em;

        @media(max-width: 900px) {
            display: flex;
            flex-direction: column;
        }
    }

    .protection-settings-fake-content {
        block-size: 18em;
    }
</style>
