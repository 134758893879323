import {
    maxLength, minLength, required,
} from '@vuelidate/validators'

export default {
    required,
    minLength: minLength(3),
    maxLength: maxLength(300),
    hasDots (value: string) {
        const firstDotPosition = value.indexOf('.')
        return firstDotPosition > 0 && firstDotPosition < value.length - 1
    },
    notFinishWithDot (value: string) {
        return value.lastIndexOf('.') < value.length - 1
    },
    noSlash (value: string) {
        return !value.includes('/')
    },
}
