<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useSessionsStore } from './store'

// TODO: export types doesnt work now https://github.com/vuejs/core/issues/4294
interface DeleteSessionProps {
    id: string
}
const componentProps = defineProps<DeleteSessionProps>()

const { t } = useI18n()
const store = useSessionsStore()
</script>

<template>
    <v-btn
        v-bind="componentProps"
        prepend-icon="Trash"
        variant="text"
        size="small"
        color="primary"
        @click="() => store.deleteSession(componentProps.id)"
        class="delete-session-buton"
    >
        <span class="delete-session-buton__label">
            {{ t('sessions.delete_device') }}
        </span>
    </v-btn>
</template>

<style>
    .delete-session-buton {
        text-transform: initial;

        &__label {
            @media(max-width: 600px) {
                display: none;
            }
        }
    }
</style>
