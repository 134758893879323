<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useTheme } from 'vuetify'

import OrangeLogo from './OrangeLogo.vue'

const { t } = useI18n()
const theme = useTheme()
</script>

<template>
    <div class="dashboard-login-layout-white-label-after-logo">
        <div>
            {{ t('common.white_label.child_online_protection') }}
        </div>
        <div class="dashboard-login-layout-white-label-after-logo__second-line">
            <span>{{ t('common.white_label.powered_by') }}</span>
            <OrangeLogo :fill="theme.current.value.colors.primary" />
        </div>
    </div>
</template>

<style>
    .dashboard-login-layout-white-label-after-logo {
        font-size: 14px;
        color: rgb(var(--v-theme-subHeader));
        margin-top: 1em;

        &__second-line {
            margin-top: 0.5em;
            display: flex;
            gap: 0.3em;
            align-items: center;
        }
    }
</style>
