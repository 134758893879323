<script setup lang="ts">
interface PolicyWidgetIconProps {
    icon: string
}

const props = defineProps<PolicyWidgetIconProps>()
</script>

<template>
    <div class="kidzonet-dashboard-widget-icon">
        <v-icon
            :icon="props.icon"
            color="white"
            size="28"
        />
    </div>
</template>

<style>
    .kidzonet-dashboard-widget-icon {
        min-width: 3.5em;
        width: 3.5em;
        height: 3.5em;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media(max-width: 600px) {
            display: none;
        }
    }
</style>
