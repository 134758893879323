<script lang="ts">
import { NavigationGuardNext, RouteLocationNormalized, useRouter } from 'vue-router'
import { useAuthStore } from '@kidzonet/pinia/UserStore'
import { defineAsyncComponent } from 'vue'

export default {
    components: {
        Layout: defineAsyncComponent(() => import('./Layout.vue')),
    },

    beforeRouteEnter (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
        next((vm) => {
            // eslint-disable-next-line ts/prefer-ts-expect-error
            // @ts-ignorework with types router guard now
            if (vm.isAuthenticated) {
                // eslint-disable-next-line ts/ban-ts-comment,  ts/prefer-ts-expect-error
                // @ts-ignore
                vm.$router.push({ name: 'home' })
            }
        })
    },

    setup () {
        const store = useAuthStore()
        if (store.isAuthenticated) {
            const router = useRouter()
            router.push({ name: 'home' })
        }

        return { isAuthenticated: store.isAuthenticated }
    },
}
</script>

<template>
    <Layout v-if="!isAuthenticated">
        <template #logo>
            <slot name="logo" />
        </template>
        <template #after-logo>
            <slot name="after-logo" />
        </template>
    </Layout>
</template>
