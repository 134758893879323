<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
    <h1 class="main-page-header__header">
        {{ t('dashboard_v2.mobile.header') }}
    </h1>
</template>
