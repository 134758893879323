<script lang="ts" setup>
import { computed, defineAsyncComponent } from 'vue'
import { useTheme } from 'vuetify'
import Logo from '@kidzonet/logo'
import LoginLayout from '@kidzonet/login-layout'
import { useEnvVar } from '@kidzonet/vue3-env-var'

const theme = useTheme()

const saas = useEnvVar('VITE_SAAS')

const showOrangeCameroonAfterLogo = computed(() => saas === 'orange-cameroon')

const OrangeCameroonAfterLogo = defineAsyncComponent(() => import('./OrangeCameroonAfterLogo.vue'))
</script>

<template>
    <LoginLayout>
        <template #logo>
            <Logo
                :fill="theme.current.value.colors.defaultPrimary"
            />
        </template>
        <template #after-logo>
            <OrangeCameroonAfterLogo
                v-if="showOrangeCameroonAfterLogo"
            />
        </template>
    </LoginLayout>
</template>
