<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import ShowPolicyButton from './DescriptionButton.vue'

const { t } = useI18n()
</script>

<template>
    <div class="protection-policy-header">
        <h1 class="main-page-header__header">
            {{ t('dashboard_v2.policy_page.header') }}
        </h1>
        <ShowPolicyButton />
    </div>
</template>

<style>
    .protection-policy-header {
        display: flex;
        gap: 1em;
        align-items: center;
    }
</style>
