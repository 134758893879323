<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useEnvVar } from '@kidzonet/vue3-env-var'

import LoginForm from './Form.vue'

const { t } = useI18n()
const showContent = computed(() => useEnvVar('VITE_SHOW_SIGNUP'))
</script>

<template>
    <div
        v-if="showContent"
        class="register-page"
    >
        <h1 class="login-header font-bold">
            {{ t('login.register.header') }}
        </h1>
        <LoginForm />
    </div>
</template>

<style>
    .register-page {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        @media(max-width: 600px) {
            flex-grow: 0;
        }
    }

    .login-header {
        margin: 2em 0 2.5em;
        font-size: 1.5em;

        @media(max-width: 600px) {
            margin: 1em 0;
        }

        @media(max-height: 900px) {
            margin: 1em 0;
        }
    }
</style>
