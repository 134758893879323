<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { useNetsStore } from '@kidzonet/dashboard-nets-store'

const { t } = useI18n()
const store = useNetsStore()

const addAddress = async () => {
    await store.addNet(store.myIP)
}
</script>

<template>
    <div class="nets-my-ip-form">
        <v-text-field
            :label="t('dashboard_nets.my_ip')"
            :model-value="store.myIP"
            autocapitalize="none"
            variant="outlined"
            color="primary"
            clearable
            disabled
        />
        <v-btn
            class="nets-my-ip-form__button"
            @click="addAddress"
            color="primary"
            rounded="lg"
            size="x-large"
        >
            {{ t('common.buttons.add') }}
        </v-btn>
    </div>
</template>

<style>
    .nets-my-ip-form {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5em;

        @media(max-width: 600px) {
            flex-direction: column;
        }

    }
</style>
