<script setup lang="ts">
import { computed } from 'vue'
import { useIsRtl } from '@kidzonet/vue-i18n-package'

const isRtl = useIsRtl()

const icon = computed(() => {
    if (isRtl) {
        return 'chevron_left'
    }
    return 'chevron_right'
})
</script>

<template>
    <v-icon
        :icon="icon"
        color="subHeader"
    />
</template>
