<script setup lang="ts">
import { useLoadLocaleMessages } from '@kidzonet/vue-i18n-package'
import { useStatsStore } from '@kidzonet/dashboard-stats-store'
import { useClickOnCurrentMenuItemListener } from '@kidzonet/vue3-event-bus'
import useSimpleUIGUard from '@kidzonet/use-simple-ui-guard'

import Content from './Content/Content.vue'

useLoadLocaleMessages('categories')
useLoadLocaleMessages('user_statistic')

const store = useStatsStore()

store.dnsLog = []
store.fetchStatistic()

useClickOnCurrentMenuItemListener(store.fetchStatistic)
useSimpleUIGUard()
</script>

<template>
    <Content />
</template>
