<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { Address4, Address6 } from 'ip-address'
import { useVuelidate } from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'

const { t } = useI18n()
const address = ref('')

const updateAddress = (value: string) => {
    address.value = value
}

const rules = computed(() => ({
    address: {
        required,
        minLength: minLength(8),
        addressValidator (value: string) {
            if (value) {
                try {
                    const addr4 = new Address4(value)
                    return addr4.isCorrect()
                } catch (e) {}

                try {
                    const addr6 = new Address6(value)
                    return addr6.isCorrect()
                } catch (e) {}
            }
            return false
        },
    },
}))

// eslint-disable-next-line ts/ban-ts-comment,  ts/prefer-ts-expect-error
// @ts-ignore
const $v = useVuelidate(rules, { address })
const buttonIsDisabled = computed(() => $v.value.$invalid)

const addAddress = async () => {
    $v.value.address.$touch()
    if (buttonIsDisabled.value) {
        return
    }
    await window.NETS_STORE.addNet(address.value)
    address.value = ''
    await nextTick()
    $v.value.address.$reset()
}

const clear = () => {
    address.value = ''
    $v.value.address.$reset()
}

const blur = () => $v.value.address.$touch()

const errors = computed(() => {
    const errors: string[] = []
    if (!$v.value.address.$dirty || address) {
        $v.value.address.$reset()
        return errors
    }
    if (!$v.value.address.addressValidator) {
        errors.push(t('dashboard_nets.errors.invalidAddress'))
    }
    return errors
})
</script>

<template>
    <div class="nets-add-form">
        <slot
            name="input"
            :enter="addAddress"
            :clear="clear"
            :blur="blur"
            :error-messages="errors"
            :input-value="address"
            :update-input-value="updateAddress"
        >
            <v-text-field
                :label="t('dashboard_nets.add')"
                v-model="address"
                autocapitalize="none"
                variant="outlined"
                color="primary"
                clearable
                :error-messages="errors"
                @keyup.enter="addAddress"
                @click:clear="clear"
                @blur="blur"
            />
        </slot>
        <slot
            name="button"
            @click="addAddress"
            :disabled="buttonIsDisabled"
        >
            <v-btn
                class="nets-add-form__button"
                @click="addAddress"
                :disabled="buttonIsDisabled"
                color="primary"
                rounded="lg"
                size="x-large"
            >
                {{ t('common.buttons.add') }}
            </v-btn>
        </slot>
    </div>
</template>

<style>
    .nets-add-form {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5em;

        @media(max-width: 600px) {
            flex-direction: column;
        }

    }
</style>
