import { useSettingsStore } from '@kidzonet/dashboard-settings-store'
import { useRouter } from 'vue-router'

export default () => {
    const store = useSettingsStore()
    const router = useRouter()

    const redirectToHome = () => {
        router.push({ name: 'home' })
    }

    store.$subscribe((mutation, state) => {
        if (state.simpleUI) {
            redirectToHome()
        }
    })
}
