<script lang="ts" setup>
import '@fortawesome/fontawesome-free/css/all.css'
import { storeToRefs } from '@kidzonet/pinia'

import { register } from 'timeago.js'
import arLocale from 'timeago.js/esm/lang/ar'
import frLocale from 'timeago.js/esm/lang/fr'
import { useI18n } from 'vue-i18n'
import { useLoadLocaleMessages } from '@kidzonet/vue-i18n-package'

import { useClickOnCurrentMenuItemListener } from '@kidzonet/vue3-event-bus'

import SessionItem from './Item.vue'
import { useSessionsStore } from './store'

// TODO: export types doesnt work now https://github.com/vuejs/core/issues/4294
interface SessionListProps {
    userId?: string | number
}
const props = withDefaults(defineProps<SessionListProps>(), {
    userId: '',
})

const store = useSessionsStore()
const { isSessionLoading } = storeToRefs(store)

const refreshData = () => {
    store.getSessions(props?.userId)
}

refreshData()

const { locale, t } = useI18n()
if (locale.value === 'ar') {
    register('ar', arLocale)
}
if (locale.value === 'fr') {
    register('fr', frLocale)
}

useClickOnCurrentMenuItemListener(refreshData)

useLoadLocaleMessages('sessions')
</script>

<template>
    <div>
        <slot name="header">
            <h2>{{ t('sessions.header') }}</h2>
        </slot>
        <v-progress-linear
            color="primary"
            indeterminate
            v-if="isSessionLoading"
            height="2"
        />
        <div v-else>
            <SessionItem
                v-for="item in store.sessions"
                :key="item.sessionId"
                :item="item"
                class="kidzonet-dashboard-widget"
            />
        </div>
    </div>
</template>
