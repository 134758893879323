<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQrCodeStore } from '@kidzonet/dashboard-qr-code-store'

const store = useQrCodeStore()

const { t } = useI18n()
</script>

<template>
    <div class="stale-token-overlay">
        <v-btn
            prepend-icon="MobileRefreshQRCode"
            class="text-primary mb-4"
            size="x-large"
            rounded="lg"
            @click="store.getToken"
        >
            {{ t('dashboard_v2.mobile.refresh') }}
        </v-btn>
    </div>
</template>

<style>
    .stale-token-overlay {
        position: absolute;
        display: flex;
        inline-size: 362px;
        block-size: 362px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(220, 220, 220, 0.9);
        border-radius: 16px;
        padding: 1em;

        &__message {
            margin-block-end: 2em;
            color: #000;
        }

        @media (max-width: 500px) {
            inline-size: 200px;
            block-size: 200px;
        }
    }
</style>
