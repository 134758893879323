<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import LoginForm from './Form.vue'

const { t } = useI18n()
</script>

<template>
    <div class="login-page">
        <h1 class="login-header font-bold">
            {{ t('login.header') }}
        </h1>
        <LoginForm />
    </div>
</template>

<style>
    .login-page {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    .login-header {
        margin: 2em 0 2.5em;
        font-size: 1.5em;

        @media(max-width: 600px) {
            margin: 1em 0;
        }

        @media(max-height: 900px) {
            margin: 1em 0;
        }
    }
</style>
