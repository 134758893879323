import {
    CreateExclusionResponseInterface,
    DeleteExclusionResponseInterface,
    ExclusionCreateInterface,
    ExclusionListParams,
    GetExclusionListResponseInterface,
} from './types'
import { ALLOWED, BLOCKED, exclusionListResponse } from './Serializers'

declare global {
    // eslint-disable-next-line vars-on-top, no-var
    var DashboardApiClient: any
    // eslint-disable-next-line vars-on-top, no-var
    var IspApiClient: any
    // eslint-disable-next-line vars-on-top, no-var
    var ExclusionListsApiClient: {
        getList: (query: ExclusionListParams) => GetExclusionListResponseInterface
        create: (params: ExclusionCreateInterface) => CreateExclusionResponseInterface
        remove: (id: number) => DeleteExclusionResponseInterface
    }
}

export const getIspApiExclusionList = (query: ExclusionListParams): GetExclusionListResponseInterface => {
    return globalThis.IspApiClient.GET('/v1/public/isp/exclusions', {
        params: {
            query,
        },
    })
}

export const getDashboardExclusionList = (query: ExclusionListParams): GetExclusionListResponseInterface => {
    return globalThis.DashboardApiClient.GET('/v1/public/dashboard/exclusions', {
        params: {
            query,
        },
    })
}

export const addIspApiDomain = (body: ExclusionCreateInterface): CreateExclusionResponseInterface => {
    return globalThis.IspApiClient.POST('/v1/public/isp/exclusions', {
        body,
    })
}

export const addDashboardDomain = (body: ExclusionCreateInterface): CreateExclusionResponseInterface => {
    return globalThis.DashboardApiClient.POST('/v1/public/dashboard/exclusions', {
        body,
    })
}

export const deleteIspApiDomain = (id: number): DeleteExclusionResponseInterface => {
    return globalThis.IspApiClient.DELETE(`/v1/public/isp/exclusions/${id}`)
}

export const deleteDashboardDomain = (id: number): DeleteExclusionResponseInterface => {
    return globalThis.DashboardApiClient.DELETE(`/v1/public/dashboard/exclusions/${id}`)
}

interface GetExclusionListParamsInterface {
    page: number
    perPage: number
    desc: boolean
}

export const getAllowedList = async (
    { page = 1, perPage = 20, desc = false }: GetExclusionListParamsInterface = { page: 1, perPage: 20, desc: false },
) => {
    const resp = await globalThis.ExclusionListsApiClient.getList({
        type: ALLOWED,
        sort: 'DOMAIN',
        page,
        per_page: perPage,
        desc,
    })
    return exclusionListResponse(resp)
}

export const getBlockedList = async (
    { page = 1, perPage = 20, desc = false }: GetExclusionListParamsInterface = { page: 1, perPage: 20, desc: false },
) => {
    const resp = await globalThis.ExclusionListsApiClient.getList({
        type: BLOCKED,
        sort: 'DOMAIN',
        page,
        per_page: perPage,
        desc,
    })
    return exclusionListResponse(resp)
}

export const addExclusion = async ({ domain, type }: ExclusionCreateInterface) => {
    const { data, error } = await globalThis.ExclusionListsApiClient.create({
        type,
        domain,
    })
    if (data) {
        return data
    }
    if ('status' in error && error?.status === 409) {
        return error
    }
    throw error
}

export const deleteExclusion = async (id: number) => {
    const { data, error } = await globalThis.ExclusionListsApiClient.remove(id)
    if (data) {
        return true
    }
    if ('status' in error && error?.status === 404) {
        return true
    }
    throw error
}
