import { defineStore } from '@kidzonet/pinia'

import { fetchPerBlockedCatActivity } from '@kidzonet/ts-api-dashboard-activity'

interface ActivityItemInterface {
    catId: number
    requestsCount: number
    color?: string
}

export const usePerCatActivityStore = defineStore('activity-stats', {
    state: () => {
        const activityList: ActivityItemInterface[] = []
        return {
            activityList,
            loading: false,
        }
    },
    actions: {
        async fetchPerCatActivity () {
            this.loading = true
            let activity: ActivityItemInterface[] = await fetchPerBlockedCatActivity() || []
            if (activity.length > 4) {
                activity = activity.filter(({ catId }) => catId !== 0).slice(0, 4)
            }
            this.activityList = activity
            this.loading = false
        },
    },
})
