<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useSettingsStore } from '@kidzonet/dashboard-settings-store'
import SafeSearchIcon from '@kidzonet/dashboard-policy-icons/src/SafeSearch.vue'

import PolicyItem from '@kidzonet/dashboard-policy-widget'
import Switcher from './Switcher.vue'

const { t } = useI18n()

const store = useSettingsStore()
const change = () => {
    store.setSafeSearch(!store.safeSearch)
}
</script>

<template>
    <PolicyItem
        :is-active="store.safeSearch"
        :header="t('common.manual_policy.safe_search')"
        :subheader="t('common.manual_policy.safe_search_description')"
        @click="change"
    >
        <template #icon>
            <SafeSearchIcon />
        </template>
        <slot>
            <Switcher
                :model-value="store.safeSearch"
            />
        </slot>
    </PolicyItem>
</template>
