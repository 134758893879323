<script setup lang="ts">
import { computed } from 'vue'
import { useTheme } from 'vuetify'
import { BlockYouTubeColors } from '@kidzonet/consts/src/PolicyColors'
import IconWrapper from './BaseIcon.vue'

const theme = useTheme()

const iconColor = computed(
    () => theme.global.current.value.dark
        ? BlockYouTubeColors.icon.dark
        : BlockYouTubeColors.icon.light,
)
const backgroundColor = computed(
    () => theme.global.current.value.dark
        ? BlockYouTubeColors.background.dark
        : BlockYouTubeColors.background.light,
)
</script>

<template>
    <IconWrapper
        :style="{ backgroundColor }"
    >
        <v-icon
            icon="PolicyIconBlockYoutube"
            :color="iconColor"
            size="24"
        />
    </IconWrapper>
</template>
