<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import LanguageSelector from '@kidzonet/language-selector'

const { t, locale } = useI18n({ useScope: 'global' })
const model = ref(locale.value)

const changeLanguage = () => {
    localStorage.setItem('locale', String(model.value))
    window.location.reload()
}
</script>

<template>
    <LanguageSelector
        v-model="model"
        @update:model-value="changeLanguage"
        :label="t('web_common.account.settings.language_select')"
    />
</template>
