import createClient from 'openapi-fetch'

import type { paths } from '@kidzonet/ts-api-generator/clients/dashboard'
import getRestApiUrl from '@kidzonet/get-rest-api-url'
import { AuthMiddleware, ErrorHandlingMiddleware } from '@kidzonet/ts-api-localstorage-middleware'

declare global {
    // eslint-disable-next-line vars-on-top, no-var
    var DashboardApiClient: any
}

export default () => {
    globalThis.DashboardApiClient = createClient<paths>({ baseUrl: getRestApiUrl() })
    globalThis.DashboardApiClient.use(AuthMiddleware)
    globalThis.DashboardApiClient.use(ErrorHandlingMiddleware)
}
