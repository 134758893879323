import { useEnvVar } from '@kidzonet/vue3-env-var'

export const isRamadanTime = useEnvVar('VITE_SHOW_RAMADAN_TIME_POLICY')

export const getName = (name: string) => {
    if (name === 'kids_home_alone' && isRamadanTime) {
        return 'ramadan_time'
    }
    return name
}

export const getPolicyName = (policy: string) => {
    const name = getName(policy)
    return window.KIDZONET_VUE_I18N.t(`policy.${name}.name`)
}

export const getPolicyDescription = (policy: string) => {
    const name = getName(policy)
    return window.KIDZONET_VUE_I18N.t(`policy.${name}.description`)
}
