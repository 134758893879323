import {
    AuthInfoResponseInterface,
    ChangePasswordResponseInterface,
    GetSHortRefreshTokenResponseInterface,
    LoginResponseInterface,
    RefreshResponseInterface,
    SignUpResponseInterface,
    SignUpSchema,
} from './types'

declare global {
    // eslint-disable-next-line vars-on-top, no-var
    var DashboardAuthApiClient: any
    // eslint-disable-next-line vars-on-top, no-var
    var getRefreshToken: () => string | null
}

export const refresh = async (refresh_token: string) => {
    const { error }: RefreshResponseInterface = await globalThis.DashboardAuthApiClient.POST(
        '/v1/public/auth/refresh', {
            body: {
                refresh_token,
            },
        },
    )
    if (error) {
        return false
    }
    return true
}

export const getAuthInfo = async () => {
    const { data, error }: AuthInfoResponseInterface = await globalThis.DashboardAuthApiClient.GET(
        '/v1/public/auth/auth_info',
    )
    if (error) {
        throw error
    }
    return { user: data.user, admin: data.admin, sessionId: data.session_id }
}

export const revoke = async () => {
    const token = await globalThis.getRefreshToken()
    const { error }: AuthInfoResponseInterface = await globalThis.DashboardAuthApiClient.POST(
        '/v1/public/auth/revoke', {
            body: {
                token,
            },
        },
    )
    if (error) {
        throw error
    }
    return true
}

export const getShortRefreshToken = async () => {
    const { data, error }: GetSHortRefreshTokenResponseInterface = await globalThis.DashboardAuthApiClient.GET(
        '/v1/public/auth/short_refresh_token',
    )
    if (error) {
        throw error
    }
    return data.token
}

export const authenticate = async (body: { username: string, password: string }) => {
    const { error }: LoginResponseInterface = await globalThis.DashboardAuthApiClient.POST(
        '/v1/public/auth/login', { body },
    )
    if (error) {
        if ('status' in error && error.status === 401) {
            return error.detail
        }
        console.error(error)
        return error
    }
    return true
}

export const signUpUser = async (body: SignUpSchema) => {
    const { error }: SignUpResponseInterface = await globalThis.DashboardAuthApiClient.POST(
        '/v1/public/auth/signup', { body },
    )
    if (error) {
        console.error(error)
        return error
    }
    return true
}

export const resetPassword = async ({ email, mobile = false }: { email: string, mobile: boolean }) => {
    await globalThis.DashboardAuthApiClient.POST(
        '/v1/public/auth/reset_password', {
            body: {
                email,
                mobile,
            },
        },
    )
    return true
}

export const changePassword = async ({ token, newPassword }: { token: string, newPassword: string }) => {
    const { error }: ChangePasswordResponseInterface = await globalThis.DashboardAuthApiClient.POST(
        `/v1/public/auth/change_password?token=${token}`, {
            body: {
                new_password: newPassword,
            },
        },
    )
    if (error) {
        if ('status' in error && error.status === 401) {
            return false
        }
        throw error
    }
    return true
}
