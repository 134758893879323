import { defineStore } from '@kidzonet/pinia'
import { changeSortOrder, orderByType } from '@kidzonet/store-helpers/sort'
import { addExclusion, deleteExclusion, getAllowedList, getBlockedList } from '@kidzonet/ts-api-exclusion-lists'
import { ALLOWED, BLOCKED } from '@kidzonet/ts-api-exclusion-lists/src/Serializers'
import { ExclusionType } from '@kidzonet/ts-api-exclusion-lists/src/types'

interface DomainInterface {
    domain: string
    id: number
    type: ExclusionType
}

export const DEFAULT_PER_PAGE = 10

const isUniqueDomain = (list: DomainInterface[], value: string) => list.every(
    ({ domain }) => domain !== value,
)

export const useCustomPolicyStore = defineStore('custom-policy', {
    state: () => {
        const allowedDomains: DomainInterface[] = []
        const blockedDomains: DomainInterface[] = []
        const allowedListSortOrder: orderByType = 'asc' as orderByType
        const blockedListSortOrder: orderByType = 'asc' as orderByType
        return {
            allowedDomainsLoading: false,
            blockedDomainsLoading: false,
            allowedDomains,
            blockedDomains,
            allowedDomainsPage: 1,
            blockedDomainsPage: 1,
            allowedDomainsTotal: 0,
            blockedDomainsTotal: 0,
            perPage: DEFAULT_PER_PAGE,
            allowedListSortOrder,
            blockedListSortOrder,
        }
    },
    getters: {
        allowedPagesCount: ({ allowedDomainsTotal, perPage }) => Math.ceil(allowedDomainsTotal / perPage),
        blockedPagesCount: ({ blockedDomainsTotal, perPage }) => Math.ceil(blockedDomainsTotal / perPage),
        canAddDomain: state => (
            domain: string,
        ) => isUniqueDomain(state.allowedDomains, domain) && isUniqueDomain(state.blockedDomains, domain),
    },
    actions: {
        async fetchAllowedDomainList () {
            this.allowedDomainsLoading = true
            const { items, total } = await getAllowedList({
                page: this.allowedDomainsPage,
                perPage: this.perPage,
                desc: this.allowedListSortOrder === 'desc',
            })
            this.allowedDomains = items
            this.allowedDomainsTotal = total
            this.allowedDomainsLoading = false
        },
        async fetchBlockedDomainList () {
            this.blockedDomainsLoading = true
            const { items, total } = await getBlockedList({
                page: this.blockedDomainsPage,
                perPage: this.perPage,
                desc: this.blockedListSortOrder === 'desc',
            })
            this.blockedDomains = items
            this.blockedDomainsTotal = total
            this.blockedDomainsLoading = false
        },
        async fetchAllDomains () {
            const [alowedList, blockedList] = await Promise.all([
                getAllowedList({
                    page: 1,
                    perPage: this.perPage,
                    desc: this.allowedListSortOrder === 'desc',
                }),
                getBlockedList({
                    page: 1,
                    perPage: this.perPage,
                    desc: this.allowedListSortOrder === 'desc',
                }),
            ])
            this.allowedDomains = alowedList.items
            this.blockedDomains = blockedList.items
        },
        async paginateAllowDomains (page: number) {
            this.allowedDomainsPage = page
            await this.fetchAllowedDomainList()
        },
        async paginateBlockedDomains (page: number) {
            this.blockedDomainsPage = page
            await this.fetchBlockedDomainList()
        },
        async changeSortAllowedDomains () {
            this.allowedDomainsPage = 1
            this.allowedListSortOrder = changeSortOrder(this.allowedListSortOrder)
            await this.fetchAllowedDomainList()
        },
        async changeSortBlockedDomains () {
            this.blockedDomainsPage = 1
            this.blockedListSortOrder = changeSortOrder(this.blockedListSortOrder)
            await this.fetchBlockedDomainList()
        },
        async addDomain ({ domain, type }: { domain: string, type: ExclusionType }) {
            const result = await addExclusion({ domain, type })
            if ('id' in result) {
                return { id: result.id, domain: result.domain }
            }
            if ('status' in result && result.status === 409) {
                if (type === ALLOWED) {
                    await this.fetchAllowedDomainList()
                } else {
                    await this.fetchBlockedDomainList()
                }
            }
            return null
        },
        async addAllowedDomain (domain: string) {
            this.allowedDomainsLoading = true
            // @ts-expect-error ignore api types
            const value: DomainInterface | null = await this.addDomain({
                domain, type: ALLOWED,
            })
            if (value) {
                await this.fetchAllowedDomainList()
            }
            this.allowedDomainsLoading = false
        },
        async addBlockedDomain (domain: string) {
            this.blockedDomainsLoading = true
            // @ts-expect-error ignore api types
            const value: DomainInterface | null = await this.addDomain({
                domain, type: BLOCKED,
            })
            if (value) {
                await this.fetchBlockedDomainList()
            }
            this.blockedDomainsLoading = false
        },
        async removeDomain (domain: DomainInterface) {
            await deleteExclusion(domain.id)
        },
        async removeAllowedDomain (domain: DomainInterface) {
            this.allowedDomainsLoading = true
            await this.removeDomain(domain)
            if (this.allowedDomainsPage > 1 && this.allowedDomains.length === 1) {
                this.allowedDomainsPage = this.allowedDomainsPage - 1
            }
            await this.fetchAllowedDomainList()
            this.allowedDomainsLoading = false
        },
        async removeBlockedDomain (domain: DomainInterface) {
            this.blockedDomainsLoading = true
            await this.removeDomain(domain)
            if (this.blockedDomainsPage > 1 && this.blockedDomains.length === 1) {
                this.blockedDomainsPage = this.blockedDomainsPage - 1
            }
            await this.fetchBlockedDomainList()
            this.blockedDomainsLoading = false
        },
    },
})
