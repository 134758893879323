<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useVuelidate } from '@vuelidate/core'
import { email as emailValidator, required } from '@vuelidate/validators'

import { useRestorePasswordStore } from '@kidzonet/dashboard-restore-password-store'

import EmailSent from './EmailSent.vue'

const { t } = useI18n()
const email = ref('')


const rules = computed(() => ({
    email: {
        required,
        emailValidator,
    },
}))

const $v = useVuelidate(rules, {
    email,
})
const buttonIsDisabled = computed(() => $v.value.$invalid)

const emailWasSend = ref(false)

const store = useRestorePasswordStore()

const restorePassword = async () => {
    if (buttonIsDisabled.value) {
        return
    }
    const result = await store.resetPassword(email.value)
    if (result) {
        emailWasSend.value = true
    }
}

const hideErrorMessage = () => {
    store.resetPasswordError = false
}
</script>

<template>
    <div class="login-restore-password-form">
        <h1 class="login-restore-password-header font-bold">
            {{ t('login.restore_password.header') }}
        </h1>
        <div v-if="emailWasSend">
            <EmailSent />
        </div>
        <div
            v-else
        >
            <v-text-field
                id="username_input"
                :label="t('login.email')"
                v-model.trim="email"
                autofocus
                autocapitalize="none"
                variant="outlined"
                @keyup.enter="restorePassword"
                @update:model-value="hideErrorMessage"
                color="primary"
                type="email"
            />
            <div
                class="login-restore-password-form__error"
            >
                <span v-if="store.resetPasswordError">
                    {{ t('login.restore_password.invalid_login_error') }}
                </span>
            </div>
            <v-btn
                id="sign_in_button"
                class="login-restore-password-form__button"
                @click="restorePassword"
                :disabled="buttonIsDisabled"
                color="primary"
            >
                {{ t('login.restore_password.button') }}
            </v-btn>
        </div>
    </div>
</template>
