<script setup lang="ts">
interface HeaderLinkInterface {
    title: string
    link: string
}

const props = defineProps<HeaderLinkInterface>()
</script>

<template>
    <div
        class="main-page-header-link"
    >
        <div class="main-page-header__divider" />
        <a
            :href="props.link"
            class="main-page-header-link__inner"
        >
            {{ props.title }}
        </a>
    </div>
</template>

<style>
    .main-page-header-link {
        display: flex;
        align-items: center;
        font-size: 14px;
        height: 24px;
        text-transform: capitalize;
    }

    .main-page-header-link__inner {
        text-decoration: none;
        color: rgb(var(--v-theme-header));

        &:hover {
            text-decoration: underline;
        }
    }
</style>
