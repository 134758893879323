import {
    GetActivityResponseInterface,
} from './types'

declare global {
    // eslint-disable-next-line vars-on-top, no-var
    var DashboardApiClient: any
}

export const fetchPerBlockedCatActivity = async () => {
    const { data, error }: GetActivityResponseInterface = await globalThis.DashboardApiClient.GET(
        '/v1/public/dashboard/dns_log/activity', {
            params: {
                query: { status: 'BLOCKED' },
            },
        },
    )

    if (error) {
        throw error
    }
    const { items = [] } = data
    return items.map(
        item => ({ catId: item.cat_id || 0, requestsCount: item.requests_count }),
    )
}
