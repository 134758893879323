<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useStatsStore } from '@kidzonet/dashboard-stats-store'

const store = useStatsStore()
const { t } = useI18n()
</script>

<template>
    <div class="reports-page-table-footer">
        <span>
            {{ t('dashboard_v2.reports_page.footer.total') }}
        </span>
        <span
            class="reports-page-table-footer__total"
        >
            {{ store.total }}
        </span>
    </div>
</template>

<style>
    .reports-page-table-footer {
        display: flex;
        align-items: center;
        &__total {
            padding-inline-start: 1em;
            font-weight: 700;
            font-size: 16px;
        }
    }
</style>
