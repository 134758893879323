<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
    <v-alert
        :title="t('login.restore_password.email_sent.header')"
        :text="t('login.restore_password.email_sent.details')"
        type="success"
    />
</template>
