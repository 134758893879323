<script setup lang="ts">
import { computed } from 'vue'

interface StatusIconProps {
    status: string
}

const props = defineProps<StatusIconProps>()

const color = computed(() => {
    if (['active', 'allowed_request', 'ALLOWED'].includes(props.status)) {
        return 'green'
    }
    if (['canceled', 'blocked_request', 'BLOCKED'].includes(props.status)) {
        return 'red'
    }
    if (['blocked_threat', 'BLOCKED_THREATS'].includes(props.status)) {
        return 'brown'
    }
    return 'black'
})
</script>

<template>
    <div
        class="statistic-status-selector-icon"
        :style="{ backgroundColor: color }"
    />
</template>

<style>
    .statistic-status-selector-icon {
        margin-inline-end: 0.5em;
        width: 1em;
        height: 1em;
        border-radius: 4px;
    }
</style>
