import { defineStore } from '@kidzonet/pinia'
import {
    changePassword as callChangePassword,
    resetPassword as callResetPassword,
} from '@kidzonet/ts-api-dashboard-auth'

export const useRestorePasswordStore = defineStore('restore-password', {
    state: () => ({
        token: '',
        mobile: false,
        changePasswordError: false,
        resetPasswordError: false,
    }),
    actions: {
        resetPassword (email: string) {
            this.resetPasswordError = false
            return callResetPassword({ email, mobile: this.mobile })
        },
        async changePassword (newPassword: string) {
            this.changePasswordError = false
            const result = await callChangePassword({
                token: this.token,
                newPassword,
            })
            if (!result) {
                this.changePasswordError = true
            }
            return result
        },
    },
})
