export type orderByType = 'asc' | 'desc'

export const changeSortOrder = (sort: orderByType): orderByType => {
    if (sort === 'asc') {
        return 'desc'
    }
    return 'asc'
}

export const booleanToOrderString = (value: boolean): orderByType => {
    return value ? 'desc' : 'asc'
}
