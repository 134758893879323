<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@kidzonet/pinia/UserStore'

const { t } = useI18n()
const store = useAuthStore()

if (store.isMindcontrol && !store.user.username) {
    store.fetchUserInfo()
}

const exit = () => {
    store.logout('/admin/admin/subscribers')
}
</script>

<template>
    <v-alert
        v-if="store.isMindcontrol"
        density="compact"
        type="warning"
    >
        <div class="mindcontrol-banner">
            <div>
                {{ t('web_common.mind_control_warning', { username: store.user.username }) }}
            </div>
            <v-btn
                variant="text"
                color="primary"
                @click="exit"
            >
                {{ t('common.logout') }}
            </v-btn>
        </div>
    </v-alert>
</template>

<style>
    .mindcontrol-banner {
        text-transform: capitalize;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }
</style>
