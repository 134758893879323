<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import StatisticTable from '@kidzonet/dashboard-statistic-table'

const { t } = useI18n()
</script>

<template>
    <div class="overview-latest-activity">
        <h4 class="overview-latest-activity__header font-semibold">
            {{ t('dashboard_v2.overview.latest_activity') }}
        </h4>
        <StatisticTable />
    </div>
</template>

<style>
    .overview-latest-activity {
        min-height: 250px;
        &__header {
            font-size: 16px;
            font-weight: 400;
        }

        .v-data-table-footer {
            display: none;
        }
    }
</style>
