<script setup lang="ts">
import { inject } from 'vue'
import { useRoute } from 'vue-router'
import { $busEmit } from '@kidzonet/inject-bus-emit'

interface MenuItem {
    title: string
    icon: string
    to: { name: string }
}

interface MenuProps {
    menu: MenuItem[]
}

const props = defineProps<MenuProps>()

const busEmit = inject($busEmit)

const route = useRoute()
const isActiveItem = (menuItem: MenuItem) => {
    return route?.name === menuItem.to?.name
}

const click = (item: MenuItem) => {
    if (busEmit && isActiveItem(item)) {
        // eslint-disable-next-line ts/prefer-ts-expect-error
        // @ts-ignore strange error
        busEmit('click_on_current_menu_item')
    }
}
</script>

<template>
    <v-list
        class="dashboard-main-menu-list"
        density="compact"
        nav
    >
        <v-list-item
            v-for="item in props.menu"
            :key="item.title"
            :prepend-icon="item.icon"
            :title="item.title"
            :to="item.to"
            class="dashboard-main-menu-list-item"
            active-class="dashboard-main-menu-list-item__active"
            color="primary"
            @click="() => click(item)"
        />
    </v-list>
</template>

<style>
    .dashboard-main-menu-list {
        padding: 0 1em;
    }
    .dashboard-main-menu-list-item {
        padding: 1em;
        .v-list-item-title {
            text-transform: capitalize;
            font-size: 16px;
        }

        .v-icon {
            margin-inline-end: 0.5em;
        }

        &__active {
            .v-icon {
                color: rgb(var(--v-theme-mainMenuText));
            }
            .v-list-item-title {
                color: rgb(var(--v-theme-mainMenuText));
            }
            .v-list-item__overlay {
                border-radius: 12px;
                opacity: 1;
                z-index: -1;
            }
        }
    }

    .v-list-item--active:hover > .v-list-item__overlay {
        opacity: calc(0.9 * var(--v-theme-overlay-multiplier)) !important;
    }
</style>
