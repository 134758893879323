const getEnvVariable = (name: string) => {
    let valueFromLS = localStorage.getItem(`${name}-override`) || localStorage.getItem(name)
    if (valueFromLS !== null) {
        try {
            valueFromLS = JSON.parse(valueFromLS || '')
        } catch (e) {
            return valueFromLS
        }
        return valueFromLS
    }
    return import.meta.env[name]
}

export const useEnvVar = (variable: string) => {
    return getEnvVariable(variable)
      || getEnvVariable(variable.replace('VUE_APP', 'VITE'))
      || getEnvVariable(variable.replace('VITE', 'VUE_APP'))
}
