import * as Sentry from '@sentry/vue'

import { defineStore } from 'pinia'

// eslint-disable-next-line ts/ban-ts-comment,  ts/prefer-ts-expect-error
// @ts-ignore
import { sendBCLoginMessage, sendBCLogoutMessage } from '@kidzonet/vue3-broadcast'
import { clearBrowser } from '@kidzonet/vue3-logout'

declare global {
    interface Window {
        AUTH_API: any
        NEW_AUTH_API: any
        useI18n: any
    }
}

export enum UserRoles {
    ISP_MANAGER = 'isp_manager',
    ADMIN = 'admin',
    CUSTOMER = 'customer',
    SUPERUSER = 'superuser',
    MANAGER = 'manager',
}

type UserRole = UserRoles.ADMIN | UserRoles.ISP_MANAGER | UserRoles.CUSTOMER | UserRoles.SUPERUSER | UserRoles.MANAGER

function isUserRole (value: string): value is UserRole {
    return ['admin', 'customer', 'isp_manager', 'superuser'].includes(value)
}

function localstorageToUserRole (value: string | null) {
    if (value === null) {
        return
    }
    return isUserRole(value) ? value : undefined
}

interface UserType {
    email?: string
    external_id?: string
    first_name?: string
    last_name?: string
    home_address?: string
    id?: string
    identity_number?: string
    is_active?: boolean
    passport_number?: string
    phone?: string
    role?: UserRole
    childRole?: UserRole
    username?: string
    firstName?: string
    lastName?: string
    identityNumber?: string
    passport?: string
    homeAddress?: string
}

const sanitizeNullableValues = (value?: string) => {
    if (!value || value === 'null') {
        return ''
    }
    return value
}

export const useAuthStore = defineStore('user', {
    state: () => {
        localStorage.removeItem('kidzonet-child-refresh-token-pending')
        localStorage.removeItem('kidzonet-refresh-token-pending')
        const token = localStorage.getItem('kidzonet-token') || ''
        const childToken = localStorage.getItem('kidzonet-child-token') || ''

        const user: UserType = {
            role: localstorageToUserRole(localStorage.getItem('kidzonet-role')),
            childRole: localstorageToUserRole(localStorage.getItem('kidzonet-child-role')),
        }
        const admin: UserType = {}
        const sessionId: string | undefined = ''

        return {
            user,
            token,
            childToken,
            refreshToken: '',
            isLoading: false,
            sessionId,
            admin,
        }
    },
    getters: {
        isAuthenticated: state => Boolean(state.token),
        role: state => state?.user?.childRole || state?.user?.role,
        isIspManager (): boolean {
            return this.role === UserRoles.ISP_MANAGER
        },
        isAdmin (): boolean {
            return this.role === UserRoles.ADMIN
        },
        isSuperUser (): boolean {
            return this.role === UserRoles.SUPERUSER
        },
        isManager (): boolean {
            return this.role === UserRoles.MANAGER
        },
        firstName: state => sanitizeNullableValues(state?.user?.first_name),
        lastName: state => sanitizeNullableValues(state?.user?.last_name),
        fullname () {
            if (!this.user) {
                return null
            }
            if (this.firstName || this.lastName) {
                return `${this.firstName} ${this.lastName}`
            }
            return null
        },
        isMindcontrol: state => state.childToken,
    },
    actions: {
        async authenticate (username: string, password: string) {
            const api = window?.NEW_AUTH_API?.authenticate ? window.NEW_AUTH_API : window.AUTH_API
            const result = await api.authenticate({ username, password })
            if (result === true) {
                await this.setTokensAndFetchUserInfo()
            }
            return result
        },
        async authenticateToken (token: string) {
            localStorage.setItem('kidzonet-refresh-token', token)
            const api = window?.NEW_AUTH_API?.refresh ? window.NEW_AUTH_API : window.AUTH_API
            const result = await api.refresh(token)
            if (result) {
                await this.setTokensAndFetchUserInfo()
            }
            return true
        },
        async setTokensAndFetchUserInfo () {
            this.token = localStorage.getItem('kidzonet-token') || ''
            this.refreshToken = localStorage.getItem('kidzonet-refresh-token') || ''
            await this.fetchUserInfo()
            sendBCLoginMessage()
        },
        async fetchUserInfo () {
            this.isLoading = true
            const api = window?.NEW_AUTH_API?.getAuthInfo ? window.NEW_AUTH_API : window.AUTH_API
            const info = await api.getAuthInfo()
            this.$patch({
                ...info,
                isLoading: false,
            })
            if (localStorage.getItem('kidzonet-child-token')) {
                localStorage.setItem('kidzonet-child-role', this.user?.role || '')
            } else {
                localStorage.setItem('kidzonet-role', this.user?.role || '')
            }
            Sentry.setUser({ ...info.user })
        },
        async logout (location = '/') {
            sendBCLogoutMessage()
            if (localStorage.getItem('kidzonet-refresh-token')) {
                const api = window?.NEW_AUTH_API?.revoke ? window.NEW_AUTH_API : window.AUTH_API
                await api.revoke()
            }
            clearBrowser(location)
        },
        async getUser () {
            const api = window?.NEW_AUTH_API?.getAuthInfo ? window.NEW_AUTH_API : window.AUTH_API
            const { user } = await api.getAuthInfo() || {}
            return user
        },
        async getCurrrentUserInfo () {
            if (this.user.id) {
                this.user = await this.getUser() || {}
            }
        },
        async registerUser ({
            username, password, email, first_name, last_name,
        }: {
            username: string
            password: string
            email: string
            first_name: string
            last_name: string
        }) {
            const api = window?.NEW_AUTH_API?.signUpUser ? window.NEW_AUTH_API : window.AUTH_API
            const result = await api.signUpUser({
                username, password, email, first_name, last_name,
            })
            if (result === true) {
                await this.authenticate(username, password)
            }
            return result
        },
    },
})
