<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
    <v-checkbox>
        <template #label>
            <i18n-t
                keypath="login.register.accept_tos_and_privacy"
                tag="div"
            >
                <template #tos>
                    <router-link
                        :to="{ name: 'tos' }"
                        class="register-form-link"
                        target="_blank"
                    >
                        {{ t('login.register.tos') }}
                    </router-link>
                </template>
                <template #privacy>
                    <router-link
                        :to="{ name: 'privacy' }"
                        class="register-form-link"
                        target="_blank"
                    >
                        {{ t('login.register.privacy') }}
                    </router-link>
                </template>
            </i18n-t>
        </template>
    </v-checkbox>
</template>

<style>
    .register-form-link {
        text-decoration: none;
    }
</style>
