export default [
    {
        name: 'study_time',
        single: false,
        exclude: true,
    }, {
        name: 'holiday_time',
        single: false,
        exclude: true,
    }, {
        name: 'kids_home_alone',
        single: false,
        exclude: true,
    }, {
        name: 'youtube',
        single: false,
    }, {
        name: 'social_media',
        single: false,
    }, {
        name: 'online_games',
        single: false,
    }, {
        name: 'online_music_and_video',
        single: false,
    }, {
        name: 'block_all',
        single: true,
    }, {
        name: 'allow_all',
        single: true,
    },
]

export const POLICY_DATA_LIST_NEW = [
    {
        name: 'allow_all',
        single: true,
    }, {
        name: 'holiday_time',
        single: true,
    }, {
        name: 'kids_home_alone',
        single: true,
    }, {
        name: 'study_time',
        single: true,
    }, {
        name: 'block_all',
        single: true,
    }, {
        name: 'youtube',
        single: false,
    }, {
        name: 'social_media',
        single: false,
    }, {
        name: 'online_games',
        single: false,
    }, {
        name: 'online_music_and_video',
        single: false,
    },
]

export const POLICY_DATA_LIST_V2 = [
    {
        name: 'allow_all',
        single: true,
    }, {
        name: 'holiday_time',
        single: true,
    }, {
        name: 'kids_home_alone',
        single: true,
    }, {
        name: 'study_time',
        single: true,
    }, {
        name: 'youtube',
        single: false,
    }, {
        name: 'social_media',
        single: false,
    }, {
        name: 'online_games',
        single: false,
    }, {
        name: 'online_music_and_video',
        single: false,
    },
]
