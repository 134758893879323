const defaultDarkIconColor = '#000'
const defaultLightkIconColor = '#fff'
const defaultDarkBackgroundColor = '#ccc'

export const AllowAllColors = {
    icon: {
        dark: defaultDarkIconColor,
        light: defaultLightkIconColor,
    },
    background: {
        dark: defaultDarkBackgroundColor,
        light: '#E2A87A',
    },
}

export const ProtectionOnColors = {
    icon: {
        dark: defaultDarkIconColor,
        light: defaultLightkIconColor,
    },
    background: {
        dark: defaultDarkBackgroundColor,
        light: '#369784',
    },
}

export const BlockAllColors = {
    icon: {
        dark: defaultDarkIconColor,
        light: defaultLightkIconColor,
    },
    background: {
        dark: defaultDarkBackgroundColor,
        light: '#868C9A',
    },
}

export const BlockOnlineGamesColors = {
    icon: {
        dark: defaultDarkIconColor,
        light: defaultLightkIconColor,
    },
    background: {
        dark: defaultDarkBackgroundColor,
        light: '#97356E',
    },
}

export const BlockOnlineMusicAndVideoColors = {
    icon: {
        dark: defaultDarkIconColor,
        light: defaultLightkIconColor,
    },
    background: {
        dark: defaultDarkBackgroundColor,
        light: '#43C4CD',
    },
}

export const BlockSocialMediaColors = {
    icon: {
        dark: defaultDarkIconColor,
        light: defaultLightkIconColor,
    },
    background: {
        dark: defaultDarkBackgroundColor,
        light: '#0099CB',
    },
}

export const BlockYouTubeColors = {
    icon: {
        dark: defaultDarkIconColor,
        light: defaultLightkIconColor,
    },
    background: {
        dark: defaultDarkBackgroundColor,
        light: '#F14C4A',
    },
}

export const HolidayTimeColors = {
    icon: {
        dark: defaultDarkIconColor,
        light: defaultLightkIconColor,
    },
    background: {
        dark: defaultDarkBackgroundColor,
        light: '#62269E',
    },
}

export const KidsHomeAloneColors = {
    icon: {
        dark: defaultDarkIconColor,
        light: defaultLightkIconColor,
    },
    background: {
        dark: defaultDarkBackgroundColor,
        light: '#369784',
    },
}

export const SafeSearchColors = {
    icon: {
        dark: defaultDarkIconColor,
        light: defaultLightkIconColor,
    },
    background: {
        dark: defaultDarkBackgroundColor,
        light: '#385FEB',
    },
}

export const SafeYoutubeColors = {
    icon: {
        dark: defaultDarkIconColor,
        light: defaultLightkIconColor,
    },
    background: {
        dark: defaultDarkBackgroundColor,
        light: '#363A97',
    },
}

export const StudyTimeColors = {
    icon: {
        dark: defaultDarkIconColor,
        light: defaultLightkIconColor,
    },
    background: {
        dark: defaultDarkBackgroundColor,
        light: '#CF4ACA',
    },
}
