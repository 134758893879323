import {
    GetSettingsResponseInterface,
    PatchSettingsResponseInterface,
    SettingsPatchSchema,
} from './types'

declare global {
    // eslint-disable-next-line vars-on-top, no-var
    var DashboardApiClient: any
}

export const getSettings = async () => {
    const { data, error }: GetSettingsResponseInterface = await globalThis.DashboardApiClient.GET('/v1/public/dashboard/settings')

    if (error) {
        throw error
    }
    const {
        safesearch = false,
        safeyoutube = false,
        simple_ui = false,
    } = data
    return {
        safeSearch: safesearch,
        safeYoutube: safeyoutube,
        simpleUI: simple_ui || false,
    }
}

const patchSettings = async (body: SettingsPatchSchema) => {
    const { error }: PatchSettingsResponseInterface = await globalThis.DashboardApiClient.PATCH(
        '/v1/public/dashboard/settings', {
            body,
        },
    )

    if (error) {
        throw error
    }
    return true
}

export const setSettings = async (
    { safeSearch, safeYoutube }: { safeSearch: boolean, safeYoutube: boolean },
) => patchSettings({
    safesearch: safeSearch,
    safeyoutube: safeYoutube,
})

export const setSafeSearch = (value: boolean) => patchSettings({ safesearch: value })
export const setSafeYoutube = (value: boolean) => patchSettings({ safeyoutube: value })
export const setSimpleUI = (value: boolean) => patchSettings({ simple_ui: value })
