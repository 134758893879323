import {
    DeleteSessionResponseInterface,
    GetSessionListResponseInterface,
} from './types'

import { getSessionFromResponse } from './Serializers'

declare global {
    interface Window {
        DashboardAuthApiClient: any
    }
}

export const getCurrentUserSessions = async () => {
    const { data, error }: GetSessionListResponseInterface = await window.DashboardAuthApiClient.GET(
        '/v1/public/auth/sessions',
    )

    if (error) {
        throw error
    }
    const { items = [] } = data
    return items.map(getSessionFromResponse)
}

export const getUserSessions = async (id: string) => {
    const { data, error }: GetSessionListResponseInterface = await window.DashboardAuthApiClient.GET(
        `/v1/public/auth/user/${id}/sessions`,
    )

    if (error) {
        throw error
    }
    const { items = [] } = data
    return items.map(getSessionFromResponse)
}

export const deleteSession = async (id: string) => {
    const { error }: DeleteSessionResponseInterface = await window.DashboardAuthApiClient.DELETE(
        `/v1/public/auth/sessions/${id}`,
    )

    if (error) {
        throw error
    }
    return true
}
