<script setup lang="ts">
import { useTheme } from 'vuetify'

import Logo from '@kidzonet/main-menu-logo'

const theme = useTheme()
</script>

<template>
    <Logo
        :fill="theme.current.value.colors.primary"
    />
</template>
