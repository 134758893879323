import { GlobalThemeOverrides } from 'naive-ui'

import { usePreferredDark } from '@vueuse/core'
import { DARK_MODE_NAME } from '@kidzonet/consts'

export const useTheme = () => {
    let theme = 'light'

    const darkModeIsActive = localStorage.getItem(DARK_MODE_NAME) || null

    if (darkModeIsActive === 'true' || (darkModeIsActive === null && usePreferredDark().value)) {
        theme = 'dark'
    }
    return theme
}

export const useDarkTheme = () => {
    return useTheme() === 'dark'
}

export const useThemeOverrides = (): GlobalThemeOverrides => {
    const commonConfig = {
        fontFamily: 'roboto',
        fontFamilyMono: 'roboto',
    }
    if (useDarkTheme()) {
        return {
            common: {
                ...commonConfig,
                primaryColor: '#ffffffFF',
                primaryColorHover: '#eee8f6FF',
                primaryColorPressed: '#424242FF',
            },
        }
    }
    return {
        common: {
            ...commonConfig,
            primaryColor: '#561fa1FF',
            primaryColorHover: '#eee8f6FF',
            primaryColorPressed: '#82B1FFFF',
        },
    }
}
