import { defineStore } from '@kidzonet/pinia'

import { getShortRefreshToken } from '@kidzonet/ts-api-dashboard-auth'

export const useQrCodeStore = defineStore('qr-code', {
    state: () => {
        return {
            loading: false,
            token: '',
            tokenStale: false,
            refreshInterval: 30000, // 30 seconds
        }
    },
    actions: {
        async getToken () {
            this.loading = true
            const token = await getShortRefreshToken() || ''
            if (token) {
                this.token = token
                this.tokenStale = false
                setTimeout(() => {
                    this.tokenStale = true
                }, this.refreshInterval)
            } else {
                this.token = ''
            }
            this.loading = false
        },
    },
})
