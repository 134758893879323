<script setup lang="ts">
import { computed } from 'vue'
import { useLoadLocaleMessages } from '@kidzonet/vue-i18n-package'
import { useNetsStore } from '@kidzonet/dashboard-nets-store'
import { useClickOnCurrentMenuItemListener } from '@kidzonet/vue3-event-bus'
import { useEnvVar } from '@kidzonet/vue3-env-var'
import NotFound from '@kidzonet/error-404/src/404.vue'

import Content from './Content/Content.vue'

useLoadLocaleMessages('dashboard_nets')

const store = useNetsStore()

store.fetchInterfacesList()
store.getMyIP()

useClickOnCurrentMenuItemListener(store.fetchInterfacesList)

const showContent = computed(() => useEnvVar('VITE_SHOW_NETS_IN_DASHBOARD'))
</script>

<template>
    <Content v-if="showContent" />
    <NotFound v-else />
</template>
