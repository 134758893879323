<script setup lang="ts">
import { useAuthStore } from '@kidzonet/pinia/UserStore'

interface ExtLoginProps {
    t: string
}

const props = defineProps<ExtLoginProps>()

await useAuthStore().authenticateToken(props.t)
window.location.href = '/'
</script>

<template>
    <h1>...</h1>
</template>
