<script setup lang="ts">
import { useDisplay, useTheme } from 'vuetify'
import Logo from '@kidzonet/logo'

defineEmits(['openDrawer'])

const theme = useTheme()
const { smAndDown } = useDisplay()
</script>

<template>
    <v-app-bar
        prominent
        v-if="smAndDown"
    >
        <v-app-bar-nav-icon
            variant="text"
            @click.stop="$emit('openDrawer')"
        />
        <v-toolbar-title>
            <Logo
                :fill="theme.current.value.colors.primary"
            />
        </v-toolbar-title>
    </v-app-bar>
</template>
