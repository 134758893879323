<script lang="ts">
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useAuthStore } from '@kidzonet/pinia/UserStore'
import { useReloadPageOnBroadcastMessage } from '@kidzonet/vue3-broadcast'

import DashboardContent from './DashboardContent.vue'

export default {
    components: {
        DashboardContent,
    },
    beforeRouteEnter (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
        next((vm) => {
            // @ts-expect-error no ways to work with types router guard now
            if (!vm.isAuthenticated) {
                // eslint-disable-next-line ts/ban-ts-comment,  ts/prefer-ts-expect-error
                // @ts-ignore
                vm.$router.push('/')
            }
        })
    },
    setup () {
        const store = useAuthStore()
        return {
            isAuthenticated: store.isAuthenticated,
        }
    },

    async mounted () {
        const store = useAuthStore()
        await store.fetchUserInfo()
        useReloadPageOnBroadcastMessage()
    },
}
</script>

<template>
    <div v-if="isAuthenticated">
        <DashboardContent />
    </div>
</template>

<style>
    .v-main {
        background-color: rgb(var(--v-theme-mainContentBackground));
        height: 100vh;
    }
</style>
