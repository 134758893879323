import POLICY_DATA_LIST, { POLICY_DATA_LIST_NEW, POLICY_DATA_LIST_V2 } from './policyDataList'

const ROLE_CUSTOMER = 1
const ROLE_ADMIN = 2
const THREATS_CATS = [56, 57, 59, 67]
const MATERIAL_COLORS_CHART_COLOR_LIST = [
    'red', 'purple', 'blue', 'green', 'indigo', 'orange', 'brown', 'lime', 'amber', 'cyan',
]

const LANG_NAMES = {
    ar: 'العربية',
    en: 'English',
    fr: 'Français',
    hi: 'मानक हिन्दी',
    ms: 'Bahasa Melayu',
    tr: 'Türkçe',
    id: 'Bahasa Indonesia',
}

const DARK_MODE_NAME = 'kidzonet-dark-mode'

export {
    POLICY_DATA_LIST,
    POLICY_DATA_LIST_NEW,
    POLICY_DATA_LIST_V2,
    ROLE_CUSTOMER,
    ROLE_ADMIN,
    THREATS_CATS,
    MATERIAL_COLORS_CHART_COLOR_LIST,
    LANG_NAMES,
    DARK_MODE_NAME,
}

export const avaliableCategoryList = [...Array(83).keys(), 84, 90, 91]
