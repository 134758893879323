<script lang="ts" setup>
import { inject, ref } from 'vue'
import { useSettingsStore } from '@kidzonet/dashboard-settings-store'
import { useRouter } from 'vue-router'
import { $busEmit } from '@kidzonet/inject-bus-emit'
import { sendBCReloadPageMessage } from '@kidzonet/vue3-broadcast'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const store = useSettingsStore()
const isSimpleInterface = ref(store.simpleUI)
const router = useRouter()
const emit = inject($busEmit)

const changeInterface = () => {
    store.setSimpleUI(isSimpleInterface.value)
    if (isSimpleInterface.value) {
        // @ts-expect-error strange error
        emit('activateSimpleUI')
        router.push({ name: 'home' })
    }
    sendBCReloadPageMessage()
}
</script>

<template>
    <div class="kidzonet-vuetify-switcher-overrides">
        <v-switch
            :label="t('dashboard_v2.main_menu.simple_interface_switcher')"
            v-model="isSimpleInterface"
            @change="changeInterface"
            inset
            hide-details
            id="simple_interface_switcher"
        />
    </div>
</template>

<style>
    @import '@kidzonet/styles-vuetify-switcher'
</style>
