const Home = () => import('../components/Dashboard.vue')

const Overview = () => import('@kidzonet/dashboard-overview/src/index.vue')
const OverviewHeader = () => import('@kidzonet/dashboard-overview/src/Header.vue')

const Policy = () => import('@kidzonet/dashboard-policy/src/index.vue')
const PolicyHeader = () => import('@kidzonet/dashboard-policy/src/Header/Header.vue')

const CustomPolicyCore = () => import('@kidzonet/dashboard-custom-policy')
const CustomPolicyHeader = () => import('@kidzonet/dashboard-custom-policy/src/Header.vue')
const CustomPolicyAllowedList = () => import('@kidzonet/dashboard-custom-policy-allowed-list')
const CustomPolicyBlockedList = () => import('@kidzonet/dashboard-custom-policy-blocked-list')

const Nets = () => import('@kidzonet/dashboard-nets/src/index.vue')
const NetsHeader = () => import('@kidzonet/dashboard-nets/src/Header.vue')

const Reports = () => import('@kidzonet/dashboard-reports/src/index.vue')
const ReportsHeader = () => import('@kidzonet/dashboard-reports/src/Header.vue')

const Mobile = () => import('@kidzonet/dashboard-mobile-page')
const MobileHeader = () => import('@kidzonet/dashboard-mobile-page/src/Header.vue')

const MyInformation = () => import('@kidzonet/dashboard-my-information')
const MyInformationHeader = () => import('@kidzonet/dashboard-my-information/src/Header.vue')

const Sessions = () => import('@kidzonet/dashboard-active-sessions')
const SessionsHeader = () => import('@kidzonet/dashboard-active-sessions/src/Header.vue')

export default [{
    path: '/dashboard',
    component: Home,
    name: 'home',
    redirect: { name: 'overview' },
    children: [{
        path: 'overview',
        name: 'overview',
        components: {
            content: Overview,
            header: OverviewHeader,
        },
    }, {
        path: 'policy',
        name: 'policy',
        components: {
            content: Policy,
            header: PolicyHeader,
        },
    }, {
        path: 'custom-policy',
        name: 'custom-policy',
        redirect: { name: 'allowed-list' },
        components: {
            content: CustomPolicyCore,
            header: CustomPolicyHeader,
        },
        children: [{
            path: 'allowed-list',
            name: 'allowed-list',
            component: CustomPolicyAllowedList,
        }, {
            path: 'blocked-list',
            name: 'blocked-list',
            component: CustomPolicyBlockedList,
        }],
    }, {
        path: 'nets',
        name: 'nets',
        components: {
            content: Nets,
            header: NetsHeader,
        },
    }, {
        path: 'reports',
        name: 'reports',
        components: {
            content: Reports,
            header: ReportsHeader,
        },
    }, {
        path: 'mobile-app',
        name: 'mobile-app',
        components: {
            content: Mobile,
            header: MobileHeader,
        },
    }, {
        path: 'my-information',
        name: 'my-information',
        components: {
            content: MyInformation,
            header: MyInformationHeader,
        },
    }, {
        path: 'sessions',
        name: 'sessions',
        components: {
            content: Sessions,
            header: SessionsHeader,
        },
    }],
}]
