<script setup lang="ts">
import useSimpleUIGUard from '@kidzonet/use-simple-ui-guard'
import Switcher from './Switcher.vue'
import Content from './Content.vue'

useSimpleUIGUard()
</script>

<template>
    <div>
        <Switcher class="kidzonet-dashboard-widget" />
        <Content />
    </div>
</template>
