<script setup lang="ts">
import { computed } from 'vue'

interface RadioIconProps {
    isActive?: boolean
}

const props = defineProps<RadioIconProps>()

const radioadioIcon = computed(() => {
    return props.isActive ? '$radioOn' : '$radioOff'
})
</script>

<template>
    <v-icon
        color="primary"
    >
        {{ radioadioIcon }}
    </v-icon>
</template>
