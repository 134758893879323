<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import VueClientRecaptcha from 'vue-client-recaptcha'

defineProps(['modelValue'])
const emit = defineEmits<{
    (e: 'submit'): void
    (e: 'update:modelValue', value: boolean): void
}>()

const inputValue = ref('')
const captchaCode = ref('')
const isValid = ref(false)

const getCaptchaCode = (value: string) => {
    captchaCode.value = value
}
const checkValidCaptcha = (value: boolean) => {
    isValid.value = value
    if (value) {
        emit('update:modelValue', value)
    }
}

const { t } = useI18n()

const showError = computed(() => !isValid.value && inputValue.value.length === 5)
</script>

<template>
    <div class="register-page-captcha">
        <v-text-field
            :label="t('login.register.captcha')"
            v-model="inputValue"
            :error="showError"
            autocapitalize="none"
            variant="outlined"
            color="primary"
            class="register-page-captcha__input"
            @keyup.enter="emit('submit')"
            maxlength="5"
        />
        <VueClientRecaptcha
            chars="abcdefghjkmnpqrstuxy3456789"
            :value="inputValue"
            hide-lines
            @get-code="getCaptchaCode"
            @is-valid="checkValidCaptcha"
        />
    </div>
</template>

<style>
    .register-page-captcha {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &__input {
            flex: inherit !important;
            width: 50%;
        }

        .vue_client_recaptcha {
            flex-grow: 1;
            display: flex;
            height: 60px;
            align-items: center;
            margin-inline-start: 1em;
        }
    }
</style>
