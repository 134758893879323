<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStatsStore } from '@kidzonet/dashboard-stats-store'

const { t } = useI18n()
const store = useStatsStore()
const search = ref(store.domain)

const searchData = () => {
    store.searchByDomain(search.value)
}

const buttonIsDisabled = computed(() => search.value === '' || store.loading)

const clear = () => {
    search.value = ''
    searchData()
}
</script>

<template>
    <div class="reports-table-toolbar-search">
        <v-text-field
            :label="t('common.search')"
            v-model="search"
            autocapitalize="none"
            variant="outlined"
            color="primary"
            clearable
            @keyup.enter="searchData"
            @change="searchData"
            @click:clear="clear"
            class="reports-table-toolbar-search__input"
        />
        <v-btn
            class="reports-table-toolbar-search__button"
            @click="searchData"
            :disabled="buttonIsDisabled"
            color="primary"
            rounded="lg"
            size="x-large"
        >
            {{ t('common.search') }}
        </v-btn>
    </div>
</template>

<style>
    .reports-table-toolbar-search {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5em;

        @media(max-width: 650px) {
            flex-direction: column;
            height: 10em;
        }

        &__input {
            @media(max-width: 1300px) {
                width: 270px;
                max-width: 270px;
            }
            @media(max-width: 650px) {
                width: 100%;
                max-width: 100%;
            }
        }
    }
</style>
