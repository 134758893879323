<script lang="ts" setup>
import { computed } from 'vue'
import { useTheme } from 'vuetify'
import Toggle from '@vueform/toggle'
import { useIsRtl } from '@kidzonet/vue-i18n-package'

const theme = useTheme()
const darkMode = computed(() => theme.global.current.value.dark)
const isRtl = computed(() => useIsRtl())
</script>

<template>
    <Toggle
        :class="{
            'policy-options-content-switch': true,
            'policy-options-content-switch--dark': darkMode,
            'policy-options-content-switch--rtl': isRtl,
        }"
    />
</template>

<style>
    .policy-options-content-switch {
        --toggle-bg-on: rgb(var(--v-theme-primary));
        --toggle-border-on: rgb(var(--v-theme-primary));
        --toggle-bg-off: #7A7A7A;
        --toggle-border-off: #7A7A7A;
        --toggle-ring-width: 0;
        --toggle-border: 8px;

        --toggle-width: 40px;
        --toggle-height: 12px;

        &--dark {
            --toggle-bg-on: #A6A6A6;
            --toggle-bg-off: #A6A6A6;
            --toggle-border-on: #A6A6A6;
            --toggle-border-off: #A6A6A6;
        }

        &--rtl {
            transform: scaleX(-1);
        }

        .toggle-handle {
            width: 20px;
            height: 20px;
            top: -4px;
            end: -8px;
        }

        .toggle {
            border-inline-width: 4px;
        }
    }
</style>

<style src="@vueform/toggle/themes/default.css" />
