<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDasboardPolicyStore } from '@kidzonet/dashboard-policy-store'
import { useSettingsStore } from '@kidzonet/dashboard-settings-store'

import HolidayTimeIcon from '@kidzonet/dashboard-policy-icons/src/HolidayTime.vue'
import StudyTimeIcon from '@kidzonet/dashboard-policy-icons/src/StudyTime.vue'
import KidsHomeAloneIcon from '@kidzonet/dashboard-policy-icons/src/KidsHomeAlone.vue'
import AllowAllIcon from '@kidzonet/dashboard-policy-icons/src/AllowAll.vue'
import BlockAllIcon from '@kidzonet/dashboard-policy-icons/src/BlockAll.vue'

import PolicyItem from '@kidzonet/dashboard-policy-widget'
import ScheduleExpansionPanel from '@kidzonet/dashboard-schedule'

import RadioIcon from '@kidzonet/dashboard-radio-icon'
import { getPolicyName } from '@kidzonet/use-policy'

const store = useDasboardPolicyStore()

const settingsStore = useSettingsStore()
const { t } = useI18n()

const activate = async (name: string, id?: number) => {
    if (store.activePolicies.some(item => item.id === id)) {
        return
    }
    const promiseList = []
    promiseList.push(store.activatePolicy(id))
    if (name === 'kids_home_alone') {
        promiseList.push(settingsStore.setSettings({ safeSearch: true, safeYoutube: true }))
    }
    await Promise.all(promiseList)
}

const isPoliciesLoaded = computed(() => {
    return store.singlePolicies && store.singlePolicies.length > 0
})

const firstColumn = computed(() => {
    if (!isPoliciesLoaded.value) {
        return
    }
    return [
        { ...store.singlePolicies[0] },
        { ...store.singlePolicies[1] },
    ]
})

const secondColumn = computed(() => {
    if (!isPoliciesLoaded.value) {
        return
    }
    return [
        { ...store.singlePolicies[2] },
        { ...store.singlePolicies[3] },
    ]
})
</script>

<template>
    <div
        v-if="isPoliciesLoaded"
        class="policy-options-content"
    >
        <div class="policy-options-content__column">
            <v-tooltip
                :text="t(`policy.${name}.long_description`)"
                v-for="{ name, isActive = false, id } in firstColumn"
                :key="name"
                location="bottom"
                max-width="400"
                :open-on-click="!isActive"
                :open-on-hover="false"
                :close-delay="300"
                :persistent="false"
            >
                <template #activator="{ props }">
                    <PolicyItem
                        v-bind="props"
                        :name="name"
                        :id="id"
                        :header="getPolicyName(name)"
                        subheader=""
                        :is-active="isActive"
                        @click="() => activate(name, id)"
                        class="policy-options-content__item"
                    >
                        <template #icon>
                            <BlockAllIcon v-if="name === 'block_all'" />
                            <AllowAllIcon v-else-if="name === 'allow_all'" />
                            <HolidayTimeIcon v-else-if="name === 'holiday_time'" />
                        </template>
                        <RadioIcon :is-active="isActive" />
                        <template #bottom>
                            <ScheduleExpansionPanel
                                v-if="isActive && id"
                                class="policy-options-content-schedule"
                                :name="name"
                                :id="id"
                            />
                        </template>
                    </PolicyItem>
                </template>
            </v-tooltip>
        </div>
        <div class="policy-options-content__column">
            <v-tooltip
                :text="t(`policy.${name}.long_description`)"
                v-for="{ name, isActive = false, id } in secondColumn"
                :key="name"
                location="bottom"
                max-width="400"
                :open-on-click="!isActive"
                :open-on-hover="false"
                :close-delay="300"
                :persistent="false"
            >
                <template #activator="{ props }">
                    <PolicyItem
                        v-bind="props"
                        :key="name"
                        :name="name"
                        :id="id"
                        :header="getPolicyName(name)"
                        subheader=""
                        :is-active="isActive"
                        @click="() => activate(name, id)"
                        class="policy-options-content__item"
                    >
                        <template #icon>
                            <StudyTimeIcon v-if="name === 'study_time'" />
                            <KidsHomeAloneIcon v-else-if="name === 'kids_home_alone'" />
                        </template>
                        <RadioIcon :is-active="isActive" />
                        <template #bottom>
                            <ScheduleExpansionPanel
                                v-if="isActive && id"
                                class="policy-options-content-schedule"
                                :name="name"
                                :id="id"
                            />
                        </template>
                    </PolicyItem>
                </template>
            </v-tooltip>
        </div>
    </div>
</template>

<style>
    .policy-options-content {
        display: flex;
        gap: 1em;

        @media(max-width: 600px) {
            flex-direction: column;
        }

        &__column {
            display: flex;
            flex-direction: column;
            gap: 1em;
            width: 50%;

            @media(max-width: 600px) {
                width: 100%;
            }
        }

        &__item {
            height: auto !important;
        }
    }
    .policy-options-content-schedule {
        margin-block-start: 1em;
    }
</style>
