<script setup lang="ts">
import { computed } from 'vue'
import { useTheme } from 'vuetify'
import { AllowAllColors } from '@kidzonet/consts/src/PolicyColors'

import IconWrapper from './BaseIcon.vue'

const theme = useTheme()
const iconColor = computed(
    () => theme.global.current.value.dark
        ? AllowAllColors.icon.dark
        : AllowAllColors.icon.light,
)
const backgroundColor = computed(
    () => theme.global.current.value.dark
        ? AllowAllColors.background.dark
        : AllowAllColors.background.light,
)
</script>

<template>
    <IconWrapper
        :style="{ backgroundColor }"
    >
        <v-icon
            icon="PolicyIconAllowAll"
            :color="iconColor"
            size="24"
        />
    </IconWrapper>
</template>
