<script lang="ts" setup>
import { useTheme } from 'vuetify'
import { useLoadLocaleMessages } from '@kidzonet/vue-i18n-package'
import Logo from '@kidzonet/logo'

useLoadLocaleMessages('login')
const theme = useTheme()
</script>

<template>
    <div class="login-layout">
        <div class="login-layout-form">
            <div class="login-layout-form__inner">
                <slot name="logo">
                    <Logo
                        :fill="theme.current.value.colors.primary"
                    />
                </slot>
                <slot name="after-logo" />
                <router-view />
            </div>
        </div>
        <div class="login-layout-image">
            <div class="login-layout-image__overlay" />
            <div class="login-layout-image__image" />
        </div>
    </div>
</template>

<style>
    .login-layout {
        display: flex;
        height: 100%;
    }
    .login-layout-form {
        width: 50%;

        display: flex;
        align-items: center;
        flex-direction: column;

        @media(max-width: 600px) {
            width: 100%;
        }

        &__inner {
            display: flex;
            flex-direction: column;

            margin-block-start: 5em;
            max-width: 40em;
            width: 100%;
            padding: 0 2em;
            height: 100%;

            @media(max-width: 600px) {
                margin-block-start: 2em;
                padding: 0 1em;
            }

            @media(max-height: 900px) {
                margin-block-start: 2em;
            }
        }
    }
    .login-layout-image {
        width: 50%;

        @media(max-width: 600px) {
            display: none;
        }

        &__image {
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url('@kidzonet/assets/src/new-login/background.jpg');
        }

        &__overlay {
            position: absolute;
            width: 50%;
            height: 100%;
            background-color: #5047B7;
            filter: opacity(0.25);
        }
    }
    .webp .login-layout-image__image {
        background-image: url('@kidzonet/assets/src/new-login/background.webp');
    }
</style>
