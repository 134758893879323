<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps(['value'])
const emit = defineEmits(['change'])

const pressUp = () => {
    if (props.value === 23) {
        emit('change', 0)
        return
    }
    emit('change', props.value + 1)
}

const pressDown = () => {
    if (props.value === 0) {
        emit('change', 23)
        return
    }
    emit('change', props.value - 1)
}

const hourList = computed(() => props.value.toString().padStart(2, '0').split(''))
</script>

<template>
    <div class="schedule-tiem-switcher-hour">
        <v-btn
            variant="text"
            icon
            color="subHeader"
            @click="pressUp"
        >
            <v-icon
                icon="TimePickerArrowUp"
            />
        </v-btn>
        <div class="schedule-tiem-switcher-hour-time">
            <div class="schedule-tiem-switcher-hour-time__item">
                <div class="schedule-tiem-switcher-hour-time__number">
                    {{ hourList[0] }}
                </div>
                <div class="schedule-tiem-switcher-hour-time__number">
                    {{ hourList[1] }}
                </div>
            </div>
            <div>:</div>
            <div
                class="schedule-tiem-switcher-hour-time__item"
            >
                <div class="schedule-tiem-switcher-hour-time__number">
                    0
                </div>
                <div class="schedule-tiem-switcher-hour-time__number">
                    0
                </div>
            </div>
        </div>
        <v-btn
            variant="text"
            icon
            color="subHeader"
            @click="pressDown"
        >
            <v-icon
                icon="TimePickerArrowDown"
            />
        </v-btn>
    </div>
</template>

<style>
    .schedule-tiem-switcher-hour {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .schedule-tiem-switcher-hour-time {
        display: flex;
        border: 1px solid rgb(var(--v-theme-subHeader));
        border-radius: 15px;

        &__number {
            display: inline-block;
            width: 0.7em;
            text-align: center;
        }
    }
</style>
