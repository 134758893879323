<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useDasboardPolicyStore } from '@kidzonet/dashboard-policy-store'
import HolidayTimeIcon from '@kidzonet/dashboard-policy-icons/src/HolidayTime.vue'
import StudyTimeIcon from '@kidzonet/dashboard-policy-icons/src/StudyTime.vue'
import KidsHomeAloneIcon from '@kidzonet/dashboard-policy-icons/src/KidsHomeAlone.vue'
import AllowAllIcon from '@kidzonet/dashboard-policy-icons/src/AllowAll.vue'
import BlockAllIcon from '@kidzonet/dashboard-policy-icons/src/BlockAll.vue'
import BlockYoutubeIcon from '@kidzonet/dashboard-policy-icons/src/BlockYoutube.vue'
import BlockSocialMediaIcon from '@kidzonet/dashboard-policy-icons/src/BlockSocialMedia.vue'
import BlockOnlineGamesIcon from '@kidzonet/dashboard-policy-icons/src/BlockOnlineGames.vue'
import BlockOnlineMusicAndVideoIcon from '@kidzonet/dashboard-policy-icons/src/BlockOnlineMusicAndVideo.vue'
import PolicyItem from '@kidzonet/dashboard-policy-widget'
import SafeYouTubeIcon from '@kidzonet/dashboard-policy-icons/src/SafeYouTube.vue'
import SafeSearchIcon from '@kidzonet/dashboard-policy-icons/src/SafeSearch.vue'
import { getPolicyName } from '@kidzonet/use-policy'

defineEmits(['close'])

const store = useDasboardPolicyStore()

const { t } = useI18n()
</script>

<template>
    <div class="policy-description kidzonet-dashboard-widget">
        <div class="policy-description__header">
            <h3>{{ t('dashboard_v2.policy_page.policy_description.header') }}</h3>
            <v-btn
                variant="tonal"
                density="compact"
                icon="$close"
                @click="$emit('close')"
            />
        </div>
        <div
            v-if="store.policyList && store.policyList.length > 0"
        >
            <PolicyItem
                v-for="{ name, id } in store.policyList"
                :key="name"
                :name="name"
                :header="getPolicyName(name)"
                :subheader="t(`policy.${name}.long_description`)"
                :id="id"
                class="policy-description__item"
                v-ripple="false"
            >
                <template #icon>
                    <BlockAllIcon v-if="name === 'block_all'" />
                    <AllowAllIcon v-else-if="name === 'allow_all'" />
                    <HolidayTimeIcon v-else-if="name === 'holiday_time'" />
                    <StudyTimeIcon v-else-if="name === 'study_time'" />
                    <KidsHomeAloneIcon v-else-if="name === 'kids_home_alone'" />
                    <BlockYoutubeIcon v-if="name === 'youtube'" />
                    <BlockSocialMediaIcon v-else-if="name === 'social_media'" />
                    <BlockOnlineGamesIcon v-else-if="name === 'online_games'" />
                    <BlockOnlineMusicAndVideoIcon v-else-if="name === 'online_music_and_video'" />
                </template>
                <div>&nbsp;</div>
            </PolicyItem>
            <PolicyItem
                v-ripple="false"
                :header="t(`common.manual_policy.safe_search`)"
                :subheader="t(`common.manual_policy.safe_search_description`)"
                class="policy-description__item"
            >
                <template #icon>
                    <SafeSearchIcon />
                </template>
                <div>&nbsp;</div>
            </PolicyItem>
            <PolicyItem
                v-ripple="false"
                :header="t(`common.manual_policy.safe_youtube`)"
                :subheader="t(`common.manual_policy.safe_youtube_description`)"
                class="policy-description__item"
            >
                <template #icon>
                    <SafeYouTubeIcon />
                </template>
                <div>&nbsp;</div>
            </PolicyItem>
        </div>
    </div>
</template>

<style>
    .policy-description {
        width: 700px;
        height: 600px;
        overflow-y: scroll;

        @media(max-width: 800px) {
            width: auto
        }

        &__header {
            display: flex;
            justify-content: space-between;
        }

        &__item {
            margin: 1em 0;
            &:hover {
                cursor: auto;
                background-color: inherit !important;
            }
        }
    }
</style>
