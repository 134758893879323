import { useI18n } from 'vue-i18n'
import UAParser, { IResult } from 'ua-parser-js'

export const getUserAgent = (agent: string) => {
    const parser = new UAParser()
    parser.setUA(agent)
    return parser.getResult()
}

const useDeviceInfo = (userAgent: IResult) => {
    const { vendor = '', model = '' } = userAgent.device
    if (vendor && model) {
        return `${vendor} ${model}`
    }
    const ua = userAgent.ua || ''
    const { t } = useI18n()
    if (ua.includes('kidzonet') || ua.includes('okhttp')) {
        return t('sessions.mobile_app')
    }
    return ''
}

const useBrowserInfo = (userAgent: IResult) => {
    const { name = '', version = '' } = userAgent.browser
    return `${name} ${version}`
}

const useOsInfo = (userAgent: IResult) => {
    const { name = '', version = '' } = userAgent.os
    const ua = userAgent.ua || ''
    if (ua.includes('okhttp')) {
        return 'Android'
    }
    return `${name} ${version}`
}

export const useUserAgent = (userAgent: IResult) => {
    const deviceInfo = useDeviceInfo(userAgent)
    const browserInfo = useBrowserInfo(userAgent)
    const osInfo = useOsInfo(userAgent)
    const browserAndOsInfo = `${osInfo} ${browserInfo}`
    let userAgentFallback = userAgent.ua
    if (browserAndOsInfo.trim() || deviceInfo.trim()) {
        userAgentFallback = ''
    }
    return {
        deviceInfo, browserInfo, osInfo, browserAndOsInfo, userAgentFallback,
    }
}
