const Support = () => import('@kidzonet/dashboard-support')
const SupportDesktop = () => import('@kidzonet/dashboard-support/src/Desktop.vue')
const SupportMobile = () => import('@kidzonet/dashboard-support/src/Mobile.vue')

export default [{
    path: '/support',
    component: Support,
    children: [{
        path: '',
        name: 'support',
        component: SupportDesktop,
    }, {
        path: 'mobile',
        name: 'mobile-support',
        component: SupportMobile,
    }],
}]
