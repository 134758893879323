<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useEnvVar } from '@kidzonet/vue3-env-var'
import { useSettingsStore } from '@kidzonet/dashboard-settings-store'

import Drawer from './Drawer.vue'

const { t } = useI18n()

const menu = computed(() => {
    const store = useSettingsStore()
    const result = [{
        title: t('dashboard_v2.main_menu.overview'),
        icon: 'mainMenuOverview',
        to: { name: 'overview' },
    }]
    if (!store.simpleUI) {
        result.push({
            title: t('dashboard_v2.main_menu.policy'),
            icon: 'mainMenuPolicy',
            to: { name: 'policy' },
        })
        result.push({
            title: t('dashboard_v2.main_menu.custom_policy'),
            icon: 'mainMenuCustomPolicy',
            to: { name: 'custom-policy' },
        })
        result.push({
            title: t('dashboard_v2.main_menu.reports'),
            icon: 'mainMenuReports',
            to: { name: 'reports' },
        })
    }
    if (useEnvVar('VITE_SHOW_NETS_IN_DASHBOARD')) {
        result.push({
            title: t('dashboard_v2.main_menu.nets'),
            icon: 'mainMenuNets',
            to: { name: 'nets' },
        })
    }
    result.push({
        title: t('dashboard_v2.main_menu.mobile_app'),
        icon: 'mainMenuMobileApp',
        to: { name: 'mobile-app' },
    })
    return result
})
</script>

<template>
    <Drawer :menu="menu" />
</template>
