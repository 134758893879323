<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import NoData from '@kidzonet/no-data-image'

import { useStatsStore } from '@kidzonet/dashboard-stats-store'
import { useCustomPolicyStore } from '@kidzonet/dashboard-custom-policy-store'

import StatusColumn from '@kidzonet/status-column'
import CategoryColumn from './CategoryColumn.vue'
import ActionsColumn from './ActionsColumn.vue'

const { t } = useI18n()
const store = useStatsStore()

const headers = computed(() => [{
    title: t('dashboard_v2.dns_log_table.headers.domain'),
    sortable: true,
    key: 'domain',
    width: 400,
}, {
    title: t('dashboard_v2.dns_log_table.headers.status'),
    sortable: true,
    key: 'type',
    width: 100,
}, {
    title: t('dashboard_v2.dns_log_table.headers.category'),
    sortable: false,
    key: 'domainCats',
    width: 200,
}, {
    title: t('dashboard_v2.dns_log_table.headers.time'),
    sortable: true,
    key: 'timestamp',
    width: 180,
}, {
    title: t('dashboard_v2.dns_log_table.headers.actions'),
    sortable: false,
    key: 'actions',
    width: 90,
}])

const customPolicyStore = useCustomPolicyStore()
customPolicyStore.perPage = 30

customPolicyStore.fetchAllDomains()

const changeSort = (params: { key: string, value: string }[]) => {
    // eslint-disable-next-line ts/ban-ts-comment,  ts/prefer-ts-expect-error
    // @ts-ignore
    store.changeSort(params[0].key)
}
</script>

<template>
    <v-data-table-server
        v-if="store.dnsLog.length > 0 || store.loading"
        :headers="headers"
        :items="store.dnsLog"
        :items-length="store.total"
        :loading="store.loading"
        :items-per-page="store.perPage"
        item-value="name"
        :show-select="false"
        :must-sort="!store.loading"
        :page="store.page"
        :sort-by="[{
            key: store.sortBy,
            order: store.sortName,
        }]"
        @update:sort-by="changeSort"
        class="statistic-table"
    >
        <template #item.timestamp="{ item }">
           {{ new Date(item.timestamp).toLocaleString() }}
        </template>
        <template #item.type="{ item }">
            <StatusColumn
                :status="item.type"
            />
        </template>
        <template #item.domainCats="{ item }">
            <CategoryColumn
                :category-list="item.domainCats"
            />
        </template>
        <template #item.actions="{ item }">
            <ActionsColumn
                :domain="item.domain"
            />
        </template>
    </v-data-table-server>
    <NoData
        v-else
        class="statistic-table-no-data"
    />
</template>

<style>
    .statistic-table {
        font-size: 14px;

        .v-data-table-header__content {
            text-transform: capitalize;
        }

        .v-data-table__tbody {
            font-family: Poppins-Medium,Helvetica,Arial,sans-serif!important;
        }
    }
    .statistic-table-no-data {
        height: 100%;
        flex-grow: 1;
    }
</style>
