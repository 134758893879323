<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import PolicyPage from '@kidzonet/dashboard-custom-policy-page'
import { DEFAULT_PER_PAGE, useCustomPolicyStore } from '@kidzonet/dashboard-custom-policy-store'
import { useClickOnCurrentMenuItemListener } from '@kidzonet/vue3-event-bus'

const { t } = useI18n()

const store = useCustomPolicyStore()
store.perPage = DEFAULT_PER_PAGE
store.fetchBlockedDomainList()
useClickOnCurrentMenuItemListener(store.fetchBlockedDomainList)
</script>

<template>
    <PolicyPage
        :loading="store.blockedDomainsLoading"
        :page="store.blockedDomainsPage"
        :total="store.blockedDomainsTotal"
        :list="store.blockedDomains"
        :header="t('dashboard_v2.custom_policy_page.table.main_header.blocked_domains')"
        :add-label="t('dashboard_v2.custom_policy_page.add_form.blocked_domain_label')"
        :pages-count="store.blockedPagesCount"
        :sort-order="store.blockedListSortOrder"
        @add-domain="store.addBlockedDomain"
        @remove-domain="store.removeBlockedDomain"
        @paginate="store.paginateBlockedDomains"
        @change-sort-order="store.changeSortBlockedDomains"
    />
</template>
