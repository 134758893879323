import {
    GetPolicyResponseInterface,
} from './types'

declare global {
    // eslint-disable-next-line vars-on-top, no-var
    var DashboardApiClient: any
}

export const fetchPolicyList = async () => {
    const { data, error }: GetPolicyResponseInterface = await globalThis.DashboardApiClient.GET('/v1/public/dashboard/policies')

    if (error) {
        throw error
    }
    const { items = [] } = data
    return items.map(
        ({ id, name, is_active }) => ({ id, name, isActive: is_active }),
    )
}

export const setPolicyList = async (policies: number[]) => {
    const { error }: GetPolicyResponseInterface = await globalThis.DashboardApiClient.POST(
        '/v1/public/dashboard/policies/activate', {
            body: { policies },
        },
    )

    if (error) {
        throw error
    }
    return true
}
