<script lang="ts" setup>
// TODO: export types doesnt work now https://github.com/vuejs/core/issues/4294
interface LanguageSelectorItemProps {
    title: string
    subtitle?: string
}

const props = withDefaults(defineProps<LanguageSelectorItemProps>(), {
    subtitle: '',
})
</script>

<template>
    <div class="language-selector-item">
        <div>
            {{ props.title }}
        </div>
        <div class="language-selector-item__subtitle grey--text">
            {{ props.subtitle }}
        </div>
    </div>
</template>

<style>
    .language-selector-item {
        display: flex;
        flex-direction: column;

        &__subtitle {
            font-size: 12px;
        }
    }
</style>
