<script lang="ts" setup>
import { computed } from 'vue'
import { useTheme } from 'vuetify'

const theme = useTheme()
const fill = computed(() => theme.global.current.value.dark ? '#fff' : '#E6FAF8')
const fill2 = computed(() => theme.global.current.value.dark ? '#ccc' : theme.current.value.colors.primary)
</script>

<template>
    <svg
        width="137"
        height="66"
        viewBox="0 0 137 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            y="33"
            width="21"
            height="33"
            rx="4"
            :fill="fill"
        />
        <rect
            x="29"
            width="21"
            height="66"
            rx="4"
            :fill="fill2"
        />
        <rect
            x="58"
            y="21"
            width="21"
            height="45"
            rx="4"
            :fill="fill"
        />
        <rect
            x="87"
            y="9"
            width="21"
            height="57"
            rx="4"
            :fill="fill"
        />
        <rect
            x="116"
            y="30"
            width="21"
            height="36"
            rx="4"
            :fill="fill"
        />
    </svg>
</template>
