import { createVuetify } from 'vuetify'
import { aliases, md } from 'vuetify/iconsets/md'
import { fa } from 'vuetify/iconsets/fa'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import theme from './vuetifyTheme'

export const defaultVuetifyComponentsStyle = {
    VDataTableServer: {
        noDataText: '',
        loadingText: '',
    },
}

export const defaultVuetifyConfig = {
    components,
    directives,
    icons: {
        defaultSet: 'md',
        aliases,
        sets: { fa, md },
    },
    theme,
    defaults: defaultVuetifyComponentsStyle,
}

export default createVuetify(defaultVuetifyConfig)
