<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import PolicyWidget from '@kidzonet/dashboard-overview-policy-widget'

const { t } = useI18n()
</script>

<template>
    <router-link
        :to="{ name: 'custom-policy' }"
        class="overview-custom-policy-widget-link-wrapper"
    >
        <PolicyWidget
            :header="t('dashboard_v2.overview.custom_policy_header')"
            :subheader="t('dashboard_v2.overview.custom_policy_subheader')"
            icon="OverviewCustomPolicyIcon"
            :icon-color="{
                background: 'linear-gradient(135deg, #62269E 0%, #380759 100%)',
                boxShadow: '0px 6px 4px -2px #62269E40',
            }"
        />
    </router-link>
</template>

<style>
    .overview-custom-policy-widget-link-wrapper {
        text-decoration: inherit;
        color: inherit;
    }
</style>
