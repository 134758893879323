import { GetExclusionListResponseInterface } from './types'

export const exclusionListResponse = ({ data, error }: GetExclusionListResponseInterface) => {
    if (error) {
        // @ts-expect-error strange error
        throw new Error(error)
    }
    const { items = [], total } = data
    return {
        items: items.map(item => ({ ...item })),
        total,
    }
}

export const ALLOWED = 'ALLOWED'
export const BLOCKED = 'BLOCKED'
