<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useScheduleStore } from '@kidzonet/dashboard-schedule-store'

import IntervalChip from '../IntervalChip.vue'

const store = useScheduleStore()
const { t } = useI18n()
</script>

<template>
    <div
        v-if="store.overlapErrorIntervalList.length > 0"
        class="overlapped-intervals-error-messages"
    >
        <h3>
            {{ t('dashboard_v2.policy_page.schedule.overlap_error') }}
        </h3>
        <ul class="overlapped-intervals-error-messages__error-list">
            <li
                v-for="interval in store.overlapErrorIntervalList"
                :key="interval.id"
                class="overlapped-intervals-error-messages__error-list-item"
            >
                <span class="overlapped-intervals-error-messages__policy-name">
                    {{ interval.policy }}
                </span>
                <IntervalChip
                    v-bind="interval"
                />
            </li>
        </ul>
    </div>
</template>

<style>
    .overlapped-intervals-error-messages {
        &__error-list {
            list-style-type: none;
            padding-block-start: 0.5em;
        }

        &__policy-name {
            min-inline-size: 15em;
        }

        &__error-list-item {
            display: flex;
            align-items: baseline;
            font-size: 14px;
            color: rgb(var(--v-theme-subHeader));
            text-transform: capitalize;
        }
    }
</style>
