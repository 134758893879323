import { defineStore } from '@kidzonet/pinia'

import { getConfig } from '@kidzonet/ts-api-dashboard-helpers'

export type languageType = 'ar' | 'en' | 'fr'

export const useSupportStore = defineStore('support', {
    state: () => ({
        loading: false,
        ar: {
            whatsapp: '',
            phone: '',
            email: '',
        },
        en: {
            whatsapp: '',
            phone: '',
            email: '',
        },
        fr: {
            whatsapp: '',
            phone: '',
            email: '',
        },
    }),
    getters: {
        whatsapp: state => (lang: languageType) => state[lang].whatsapp || state.en.whatsapp,
        phone: state => (lang: languageType) => state[lang].phone || state.en.phone,
        email: state => (lang: languageType) => state[lang].email || state.en.email,
    },
    actions: {
        async fetchData () {
            this.loading = true
            const { supportPageData } = await getConfig()
            if (supportPageData) {
                const { ar, en, fr } = supportPageData
                this.$patch({ ar, en, fr })
            }
            this.loading = false
        },
    },
})
