<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { format } from 'timeago.js'

import { getUserAgent } from '@kidzonet/vue3-user-agent'
import { useAuthStore } from '@kidzonet/pinia/UserStore'
import UserAgentIcon from '@kidzonet/user-agent-icon'
import { useUserAgent } from '@kidzonet/vue3-user-agent'
import DeleteSession from './DeleteSession.vue'
import { sessionItemInterface } from './types'

// TODO: export types doesnt work now https://github.com/vuejs/core/issues/4294
interface SessionItemProps {
    item: sessionItemInterface
    showAction?: boolean
}
const props = withDefaults(defineProps<SessionItemProps>(), {
    showAction: true,
})
const userAgent = getUserAgent(props.item.userAgent)
const { t, locale } = useI18n()

const {
    deviceInfo, browserAndOsInfo, userAgentFallback,
} = useUserAgent(userAgent)

const lastActivity = computed(() => format(new Date(props.item.lastActivity), locale.value))

const store = useAuthStore()
const isCurrentSession = computed(() => store.sessionId === props.item.sessionId)
</script>

<template>
    <v-list-item
        class="session-item"
    >
        <template #prepend>
            <UserAgentIcon
                :agent="userAgent"
                class="session-item__icon"
             />
        </template>
        <div class="session-item__title-container">
            <v-list-item-title
                v-if="deviceInfo"
                class="session-item__title"
            >
                {{ deviceInfo }}
            </v-list-item-title>
            <v-list-item-title
                v-if="browserAndOsInfo"
                class="session-item__title"
            >
                {{ browserAndOsInfo }}
            </v-list-item-title>
            <v-list-item-title
                v-if="userAgentFallback"
                class="session-item__title"
            >
                {{ userAgentFallback }}
            </v-list-item-title>
            <v-list-item-title
                v-if="isCurrentSession"
                class="session-item__current-session"
            >
                {{ t('sessions.current_session') }}
            </v-list-item-title>
        </div>
        <v-list-item-subtitle
            class="session-item__subtitle"
        >
            <div v-if="lastActivity">
                {{ t('sessions.last_activity') }}&nbsp;{{ lastActivity }}
            </div>
            <div
                v-if="props.item.lastIP"
                class="session-item__geolocaion"
            >
                {{ props.item.lastIP }}
            </div>
        </v-list-item-subtitle>
        <template #append>
            <v-list-item-action
                v-if="showAction"
                class="session-item__action"
            >
                <DeleteSession
                    v-if="!isCurrentSession"
                    :id="props.item.sessionId"
                />
            </v-list-item-action>
        </template>
    </v-list-item>
</template>

<style>
    .session-item {
        display: flex;

        &__icon {
            @media (max-width: 600px) {
                margin-inline-end: 16px !important;
            }

            @media (max-width: 400px) {
                display: none;
            }
        }

        &__title {
            overflow: visible;
            white-space: initial;
            font-weight: 600;
            font-size: 16px;
        }

        &__title-container {
            display: flex;
            flex-wrap: wrap;
        }

        &__action {
            display: flex;
            flex-direction: row;
            align-items: center;

            @media (max-width: 600px) {
                max-inline-size: 50px;
                flex-direction: column;
            }
        }

        &__subtitle {
            display: flex;
            flex-wrap: wrap;

            @media (max-width: 600px) {
                overflow: visible;
                white-space: initial;
            }

            @media (max-width: 350px) {
                max-inline-size: 120px;
            }
        }

        &__current-session {
            color: #7FBA7A;
            margin-inline-start: 0.5em;
            font-size: 14px;
            font-weight: 500;
        }

        &__geolocaion {
            display: flex;
            align-items: center;
            &::before {
                margin: 0 0.5em;
                content: '';
                display: inline-block;
                width: 5px;
                height: 5px;
                border-radius: 7.5px;
                background-color: rgb(var(--v-theme-subHeader));
           }
        }
    }
</style>

<style>
    /* override vuetify styles */
    .session-item {
        margin-block-end: 1em;
        .v-list-item__content {
            flex-grow: 1;
        }
        .v-list-item__prepend {
            align-self: flex-start;
            width: auto;
        }
    }
</style>
