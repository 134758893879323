declare global {
    interface Window {
        KIDZONET_VUE_I18N: any
    }
}

/**
 * get translated name of category by number or "Uncategorized"
 */
export const useGetCategoryName = (cat: string | number) => {
    const str = `categories.${cat}`
    return window.KIDZONET_VUE_I18N.te(str) ? window.KIDZONET_VUE_I18N.t(str) : window.KIDZONET_VUE_I18N.t('categories.0')
}

/**
 * for categories list remove first uncategorized item
 */
export const useCleanedCatList = (categoryList: number[] = []) => {
    if (
        categoryList
        && categoryList.length > 1
        && categoryList[0] === 0
    ) {
        return categoryList.slice(1)
    }
    if (categoryList.length === 0) {
        return [0]
    }
    return categoryList
}
