<script setup lang="ts">
import { computed } from 'vue'
import { useLoadLocaleMessages } from '@kidzonet/vue-i18n-package'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
useLoadLocaleMessages('tos')

const updationDate = computed(() => {
    return new Date('2020-04-08').toLocaleDateString()
})
</script>

<template>
    <article class="tos">
        <h1 class="tos__header">
            {{ t('tos.header') }}
        </h1>
        <section>
            <p>{{ t('tos.preambula.1') }}</p>
            <p>{{ t('tos.preambula.2') }}</p>
        </section>
        <h2>{{ t('tos.part_1.header') }}</h2>
        <section>
            <p>{{ t('tos.part_1.text') }}</p>
        </section>
        <h2>{{ t('tos.part_2.header') }}</h2>
        <section>
            <p>{{ t('tos.part_2.text_1') }}</p>
            <p>{{ t('tos.part_2.text_2') }}</p>
        </section>
        <h2>{{ t('tos.part_3.header') }}</h2>
        <section>
            <p>{{ t('tos.part_3.text') }}</p>
        </section>
        <h2>{{ t('tos.part_4.header') }}</h2>
        <section>
            <p>{{ t('tos.part_4.text_1') }}</p>
            <ol class="tos__list">
                <li class="tos__list-item">
                    {{ t('tos.part_4.items.a') }}
                </li>
                <li class="tos__list-item">
                    {{ t('tos.part_4.items.b') }}
                </li>
                <li class="tos__list-item">
                    {{ t('tos.part_4.items.c') }}
                </li>
                <li class="tos__list-item">
                    {{ t('tos.part_4.items.d') }}
                </li>
                <li class="tos__list-item">
                    {{ t('tos.part_4.items.e') }}
                </li>
                <li class="tos__list-item">
                    {{ t('tos.part_4.items.f') }}
                </li>
                <li class="tos__list-item">
                    {{ t('tos.part_4.items.g') }}
                </li>
                <li class="tos__list-item">
                    {{ t('tos.part_4.items.h') }}
                </li>
                <li class="tos__list-item">
                    {{ t('tos.part_4.items.i') }}
                </li>
                <li class="tos__list-item">
                    {{ t('tos.part_4.items.j') }}
                </li>
                <li class="tos__list-item">
                    {{ t('tos.part_4.items.k') }}
                </li>
            </ol>
            <p>{{ t('tos.part_4.text_2') }}</p>
        </section>
        <h2>{{ t('tos.part_5.header') }}</h2>
        <section>
            <p>{{ t('tos.part_5.text') }}</p>
        </section>
        <h2>{{ t('tos.part_6.header') }}</h2>
        <section>
            <p>{{ t('tos.part_6.text_1') }}</p>
            <ol class="tos__list">
                <li class="tos__list-item">
                    {{ t('tos.part_6.items.a') }}
                </li>
                <li class="tos__list-item">
                    {{ t('tos.part_6.items.b') }}
                </li>
                <li class="tos__list-item">
                    {{ t('tos.part_6.items.c') }}
                </li>
                <li class="tos__list-item">
                    {{ t('tos.part_6.items.d') }}
                </li>
                <li class="tos__list-item">
                    {{ t('tos.part_6.items.e') }}
                </li>
                <li class="tos__list-item">
                    {{ t('tos.part_6.items.f') }}
                </li>
                <li class="tos__list-item">
                    {{ t('tos.part_6.items.g') }}
                </li>
            </ol>
            <p>{{ t('tos.part_6.text_2') }}</p>
        </section>
        <h2>{{ t('tos.part_7.header') }}</h2>
        <section>
            <p>{{ t('tos.part_7.text') }}</p>
        </section>
        <h2>{{ t('tos.part_8.header') }}</h2>
        <section>
            <p>{{ t('tos.part_8.text') }}</p>
        </section>
        <h2>{{ t('tos.part_9.header') }}</h2>
        <section>
            <p>{{ t('tos.part_9.text') }}</p>
        </section>
        <h2>{{ t('tos.part_10.header') }}</h2>
        <section>
            <p>{{ t('tos.part_10.text') }}</p>
        </section>
        <h2>{{ t('tos.part_11.header') }}</h2>
        <section>
            <p>{{ t('tos.part_11.text') }}</p>
        </section>
        <h2>{{ t('tos.contuct_us.header') }}</h2>
        <section>
            <i18n-t
                keypath="tos.contuct_us.text"
                tag="p"
            >
                <template #email_address>
                    <a href="mailto:privacy@kidzonet.com">
                        privacy@kidzonet.com
                    </a>
                </template>
            </i18n-t>
        </section>
        <p>{{ t('tos.updation_date', [updationDate]) }}</p>
    </article>
</template>

<style>
    .tos {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 2em;

        &__header {
            display: block;
            margin-block-end: 1.5em;
        }

        &__list {
            margin: 1em 0;
        }

        &__list-item {
            padding-inline-start: 1em;
        }
    }
</style>
