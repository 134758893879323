import sanitizeDomain from '@kidzonet/utils-sanitize-domains'

import {
    GetDnsLogParamsInterface,
    GetDnsLogResponseInterface,
    RecordType,
} from './types'

declare global {
    // eslint-disable-next-line vars-on-top, no-var
    var DashboardApiClient: any
}

export const DNSLogSort = Object.freeze({
    timestamp: 'TIMESTAMP',
    domain: 'DOMAIN',
    domainCats: 'DOMAIN_CATS',
    type: 'TYPE',
})

const recordType = Object.freeze({
    PASSED: 0,
    CATEGORY_BLOCKED: 1,
    EXCLUSION_BLOCKED: 2,
    EXCLUSION_ALLOWED: 3,
    REDIRECTED: 4,
})

export const getDNSLog = async (query: GetDnsLogParamsInterface) => {
    const { data, error }: GetDnsLogResponseInterface = await globalThis.DashboardApiClient.GET(
        '/v1/public/dashboard/dns_log/records', {
            params: {
                query: {
                    ...query,
                    domain: (query.domain || '').trim(),
                },
            },
        },
    )

    if (error) {
        throw error
    }
    const { items = [] } = data
    return {
        page: Number(data.page),
        perPage: Number(data.per_page),
        total: Number(data.total),
        items: items.map(item => ({
            domain: sanitizeDomain(item.domain),
            timestamp: Number(item.timestamp / 1000000),
            domainCats: item.domain_cats || [],
            // @ts-expect-error api return different type than really
            type: recordType[item.type] || 0 as RecordType,
        })),
    }
}
