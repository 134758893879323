<script lang="ts" setup>
import MainMenu from '@kidzonet/dashboard-main-menu'
import { useLoadLocaleMessages } from '@kidzonet/vue-i18n-package'
import TosAlert from '@kidzonet/dashboard-tos/src/Alert.vue'
import { getDashboardExclusionList, addDashboardDomain, deleteDashboardDomain } from '@kidzonet/ts-api-exclusion-lists'
import { useSettingsStore } from '@kidzonet/dashboard-settings-store'

import ContentHeader from './Header.vue'
import Content from './Content.vue'

await useLoadLocaleMessages('dashboard_v2')

const settingsStore = useSettingsStore()
await settingsStore.fetchSettings()

window.ExclusionListsApiClient = {
    getList: getDashboardExclusionList,
    create: addDashboardDomain,
    remove: deleteDashboardDomain,
}
</script>

<template>
    <div>
        <MainMenu />
        <v-main>
            <ContentHeader />
            <Content />
            <v-spacer />
            <TosAlert />
        </v-main>
    </div>
</template>

<style>
    .v-main {
        background-color: rgb(var(--v-theme-mainContentBackground));
        height: 100vh;
        display: flex;
        flex-direction: column;
    }
</style>
