<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()

const route = useRoute()

const allowedDomainsClassList = computed(() => {
    const result = ['custom-policy-switcher-link__inner']
    if (route.name === 'allowed-list') {
        result.push('custom-policy-switcher-link__inner--active')
    }
    return result
})
const blockedDomainsClassList = computed(() => {
    const result = ['custom-policy-switcher-link__inner']
    if (route.name === 'blocked-list') {
        result.push('custom-policy-switcher-link__inner--active')
    }
    return result
})
</script>

<template>
    <div class="custom-policy-switcher">
        <div class="custom-policy-switcher-content">
            <router-link
                class="custom-policy-switcher-link"
                :to="{ name: 'allowed-list' }"
            >
                <div
                    :class="allowedDomainsClassList"
                >
                    {{ t('dashboard_v2.custom_policy_page.links.allowed_domains') }}
                </div>
            </router-link>
            <router-link
                class="custom-policy-switcher-link"
                :to="{ name: 'blocked-list' }"
            >
                <div
                    :class="blockedDomainsClassList"
                >
                    {{ t('dashboard_v2.custom_policy_page.links.blocked_domains') }}
                </div>
            </router-link>
        </div>
    </div>
</template>

<style>
    .custom-policy-switcher {
        display: inline-block;
    }

    .custom-policy-switcher-content {
        display: flex;
        gap: 1em;
        align-items: center;
    }

    .custom-policy-switcher-link {
        text-decoration: none;

        &__inner {
            color: rgb(var(--v-theme-subHeader));
            padding: 0.5em;
            transition: 0.5s;
            border-radius: 8px;

            &--active {
                color: rgb(var(--v-theme-mainMenuText));
                background-color: rgb(var(--v-theme-primary));
            }
        }
    }
</style>
