import {
    GetClientIPResponseInterface,
    GetDohSettingsResponseInterface,
    GetProviderConfigResponseInterface,
} from './types'

declare global {
    // eslint-disable-next-line vars-on-top, no-var
    var DashboardApiClient: any
}

export const getMyIPAddress = async () => {
    const { data, error }: GetClientIPResponseInterface = await globalThis.DashboardApiClient.GET(
        '/v1/public/dashboard/helpers/client_ip',
    )

    if (error) {
        throw error
    }
    return data.ip
}

export const getDohSettings = async () => {
    const { data, error }: GetDohSettingsResponseInterface = await globalThis.DashboardApiClient.GET(
        '/v1/public/dashboard/helpers/doh_settings',
    )

    if (error) {
        throw error
    }
    return {
        serverUrl: data.server_url,
        serverAddresses: data.server_addresses,
        serverName: data.server_name,
        probingUrl: data.probing_url,
    }
}

export const getConfig = async () => {
    if (globalThis.DashboardApiClient === undefined) {
        return {}
    }
    const { data, error }: GetProviderConfigResponseInterface = await globalThis.DashboardApiClient.GET(
        '/v1/public/dashboard/helpers/provider_config',
    )

    if (error) {
        throw error
    }
    return {
        supportPageUrl: data.support_page_url,
        mobileSupportPageUrl: data.mobile_support_page_url,
        macOsBypassProfileName: data.macos_bypass_profile_name,
        showRamadanTimePolicy: data.show_ramadan_time_policy,
        supportPageData: JSON.parse(JSON.stringify(data.support_page_data || '')),
    }
}
