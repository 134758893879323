<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useOnline } from '@vueuse/core'

const online = useOnline()

const { t } = useI18n()
</script>

<template>
    <v-alert
        v-if="!online"
        density="compact"
        type="warning"
        class="offline-baner"
    >
        {{ t('errors.offline') }}
    </v-alert>
</template>

<style>
    .offline-baner {
        text-transform: capitalize;
    }
</style>
