<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { notify } from '@kyvg/vue3-notification'

import { useCustomPolicyStore } from '@kidzonet/dashboard-custom-policy-store'

interface ActionsColumnProps {
    domain: string
}

const componentProps = defineProps<ActionsColumnProps>()
const { t } = useI18n()

const store = useCustomPolicyStore()

const show = computed(() => store.canAddDomain(componentProps.domain))

const addToAllowedList = async () => {
    await store.addAllowedDomain(componentProps.domain)
    notify({
        type: 'success',
        title: t(
            'dashboard_v2.reports_page.add_to_exclusion_list_dialog.message.allowed',
            { domain: componentProps.domain },
        ),
    })
}
const addToBlockedList = async () => {
    await store.addBlockedDomain(componentProps.domain)
    notify({
        type: 'success',
        title: t(
            'dashboard_v2.reports_page.add_to_exclusion_list_dialog.message.blocked',
            { domain: componentProps.domain },
        ),
    })
}
</script>

<template>
    <v-menu
        bottom
        left
        v-if="show"
    >
        <template #activator="{ props }">
            <v-btn
                variant="text"
                icon
                color="subHeader"
                v-bind="props"
            >
                <v-icon>more_vert</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item
                @click="addToAllowedList"
            >
                <v-list-item-title>
                    {{ t('dashboard_v2.reports_page.add_to_exclusion_list_dialog.allowed') }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                @click="addToBlockedList"
            >
                <v-list-item-title>
                    {{ t('dashboard_v2.reports_page.add_to_exclusion_list_dialog.blocked') }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
