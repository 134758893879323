<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

interface StatusColumnProps {
    status: 0 | 1 | 2 | 3 | 4
}

const props = defineProps<StatusColumnProps>()

const { t } = useI18n()

const label = computed(() => {
    const translations = {
        0: t('user_statistic.types.passed'),
        1: t('user_statistic.types.cat_blocked'),
        2: t('user_statistic.types.blocklist'),
        3: t('user_statistic.types.allowlist'),
        4: t('user_statistic.types.redirected'),
    }
    return translations[props.status] || ''
})

const color = computed(() => {
    if (props.status === 0 || props.status === 3 || props.status === 4) {
        return '#7FBA7A'
    }
    return '#F84747'
})
</script>

<template>
    <div class="request-status">
        <div
            class="request-status__icon"
            :style="{ backgroundColor: color }"
        />
        <span class="request-status__label">{{ label }}</span>
    </div>
</template>

<style>
    .request-status {
        display: flex;
        align-items: center;

        &__icon {
            margin-inline-end: 0.5em;
            width: 1em;
            height: 1em;
            min-width: 1em;
            min-height: 1em;
            border-radius: 4px;
        }

        &__label {
            @media (max-width: 600px) {
                display: none;
            }
        }
    }
</style>
