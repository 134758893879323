import createClient from 'openapi-fetch'

import type { paths } from '@kidzonet/ts-api-generator/clients/auth'
import getRestApiUrl from '@kidzonet/get-rest-api-url'
import { AuthMiddleware, ErrorHandlingMiddleware, getRefreshToken } from '@kidzonet/ts-api-localstorage-middleware'
import { authenticate, getAuthInfo, refresh, revoke, signUpUser } from '@kidzonet/ts-api-dashboard-auth'

declare global {
    interface Window {
        DashboardAuthApiClient: any
    }
}

export default () => {
    window.DashboardAuthApiClient = createClient<paths>({ baseUrl: getRestApiUrl() })
    window.DashboardAuthApiClient.use(AuthMiddleware)
    window.DashboardAuthApiClient.use(ErrorHandlingMiddleware)

    window.NEW_AUTH_API = {
        authenticate,
        getAuthInfo,
        refresh,
        revoke,
        signUpUser,
    }

    window.getRefreshToken = getRefreshToken
}
