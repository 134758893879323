<script setup lang="ts">
</script>

<template>
    <router-view v-slot="{ Component }">
        <v-slide-x-transition hide-on-leave>
            <component :is="Component" />
        </v-slide-x-transition>
    </router-view>
</template>
